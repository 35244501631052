import React from 'react';
import { FormattedMessage } from 'react-intl';

import { WarningModal } from '../../../base-ui/components';

export function UnexpectedProblemModal(props: {
	buttonHandler: () => void;
	altButtonText: string;
	altButtonHandler: () => void;
}) {
	return (
		<WarningModal
			headerText={
				<FormattedMessage defaultMessage="Unexpected problem" id="unexpected-problem-modal.header-text" />
			}
			contentText={
				<FormattedMessage
					defaultMessage="There was an unexpected problem with adding a card. Please retry or contact support if problem persists."
					id="unexpected-problem-modal.content-text"
				/>
			}
			buttonText={<FormattedMessage defaultMessage="Retry" id="unexpected-problem-modal.retry-button" />}
			buttonHandler={props.buttonHandler}
			altButtonText={props.altButtonText}
			altButtonHandler={props.altButtonHandler}
		/>
	);
}
