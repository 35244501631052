import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CreditSubjectRentReportingStatus } from '../../../../../functions/src/shared/credit-reporting';
import { FeatureType } from '../../../../../functions/src/shared/user';

import { Spacing, Text } from '../../../base-ui/components';

import { AccountDashboardImage } from '../../components/account-dashboard-image';
import { CreditBuilderCard } from '../../components/profile/credit-builder-card/credit-builder-card';
import { Features } from '../../components/profile/features';
import { LearnMore } from '../../components/profile/learn-more';
import { ScheduledLandlordPayments } from '../../components/profile/payments/scheduled-landlord-payments';
import { ScheduledPayments } from '../../components/profile/payments/scheduled-payments';
import { CreditBuilderProfileErrors } from '../../components/profile/profile-errors/credit-builder-profile-errors';
import { ProfileErrors } from '../../components/profile/profile-errors/profile-errors';
import { useCreditBuilderDashboard, useUserData } from '../../contexts/user-data-context';
import { AccountModals } from './account-modals/account-modals';

export const AccountDashboard = () => {
	const { user, home } = useUserData();
	const { formatMessage } = useIntl();

	const creditBuilder = useCreditBuilderDashboard();

	if (!home) return null;

	const {
		dashboard: { hasErrors, hasSomeErrors },
		creditSubject,
	} = home;

	const indirectModel = Boolean(home?.dashboard.indirectModel);

	let subtitle = formatMessage({ defaultMessage: 'Enjoy your rent day bliss!', id: 'account-dashboard-subtitle' });
	if (hasSomeErrors) {
		subtitle = formatMessage({
			defaultMessage: 'Account setup is not completed.',
			id: 'account-dashboard-subtitle-errors',
		});
		if (
			hasErrors[FeatureType.CustomRent] &&
			creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.Active
		) {
			subtitle = formatMessage({
				defaultMessage: 'Enjoy your CreditBuilder!',
				id: 'account-dashboard-subtitle-creditbuilder',
			});
		}
	}

	return (
		<>
			<AccountModals />
			<AccountDashboardImage type={hasSomeErrors ? 'error' : 'success'} />

			<Text type="title-extra-large-primary" testId="profile-title">
				<FormattedMessage
					defaultMessage={`Hi {firstName}`}
					id="account-dashboard-greeting"
					values={{ firstName: user?.firstName }}
				/>
			</Text>
			<Text type="title-large-secondary" testId="profile-subtitle">
				{subtitle}
			</Text>

			<ProfileErrors />
			<CreditBuilderProfileErrors />

			<Spacing $size="xl" />

			{creditBuilder && !indirectModel ? (
				<>
					<Text type="title-ternary">
						<FormattedMessage
							defaultMessage="Scheduled Payments"
							id="account-dashboard-scheduled-payments"
						/>
					</Text>
					<ScheduledLandlordPayments />
				</>
			) : home?.activeRentConfig ? (
				<>
					<Text type="title-ternary">
						<FormattedMessage
							defaultMessage="Scheduled Payments"
							id="account-dashboard-scheduled-payments"
						/>
					</Text>
					<ScheduledPayments />
				</>
			) : null}
			<CreditBuilderCard />
			<Features />
			<LearnMore />
		</>
	);
};
