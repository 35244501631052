import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { BusinessEventType } from '../../../../../../functions/src/shared/business-events';

import { ConfigurationModal } from '../../../../base-ui/components/Modal/modal';

import { useInterface } from '../../../contexts/hooks';
import { useUserData } from '../../../contexts/user-data-context';
import { useTrackOnboardingEvent } from '../../../utils/track-onboarding-event';

export function EtransferZumRailsModal() {
	const [shown, setShown] = useState(false);
	const { onboardingEvents, home } = useUserData();
	const { setModal, closeModal } = useInterface();
	const trackEvent = useTrackOnboardingEvent();

	let modal: null | React.ReactNode = null;
	const confirmedModal = BusinessEventType.OnboardingETransferZumRailsNotificationEnd in onboardingEvents;

	if (home?.activeRentConfig && home?.editableRent && !confirmedModal) {
		modal = (
			<ConfigurationModal
				headerText={
					<FormattedMessage
						defaultMessage="Zenbase e-Transfer payments are processed by Zum Rails."
						id="account-dashboard.etransfers-zumrails-modal-header"
					/>
				}
				contentText={
					<FormattedMessage
						defaultMessage="When making your payments you will see a reference to Zum Rails in the confirmation screens and emails."
						id="account-dashboard.etransfers-zumrails-modal-content"
					/>
				}
				buttonText={<FormattedMessage defaultMessage="I understand" id="common.i-understand" />}
				buttonHandler={() => {
					trackEvent.mutate(
						{ type: BusinessEventType.OnboardingETransferZumRailsNotificationEnd },
						{
							onSuccess() {
								closeModal();
							},
						},
					);
				}}
			/>
		);
	}

	useEffect(() => {
		if (modal && !shown) {
			setModal(modal);
			setShown(true);
			trackEvent.mutate({ type: BusinessEventType.OnboardingETransferZumRailsNotificationStart });
		}
	}, [modal, shown]);

	return modal;
}
