import React, { ReactNode, useEffect, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';

import { publicConfig } from '../../../../../functions/src/shared/config';

import { getEnvironment } from '../../../shared/environment';
import { trpc } from '../../../shared/trpc/client';

import { Alert, AlertTitle, Button, Footer, Loader, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { useInterface } from '../../contexts/hooks';
import { links } from '../../Router/paths';
import { CardFlow, cardFlow, cardFlowProps } from './2-2-0-select-payment-method';

const stateSchema = z
	.object({
		flow: z.enum(cardFlow),
	})
	.nullable()
	.optional();

export const AddCreditCard = (props: { flow: CardFlow }) => {
	const location = useLocation();

	const flow = stateSchema.parse(location.state)?.flow ?? props.flow;
	const { backLink } = cardFlowProps(flow);

	const [stripePromise] = useState(() => loadStripe(publicConfig.stripe[getEnvironment()].publicKey));

	const paymentIntent = trpc.user.paymentCards.createSaveCardPaymentIntent.useMutation();
	useEffect(() => {
		paymentIntent.mutate();
	}, []);

	const clientSecret = paymentIntent.data?.clientSecret;

	if (paymentIntent.isLoading || !clientSecret) {
		return (
			<Page backLink={backLink}>
				<Text center>
					<FormattedMessage
						defaultMessage="Initializing credit card form..."
						id="add-credit-card.initializing-credit-card-form"
					/>
				</Text>
				<CardFormLoader>
					<Loader />
				</CardFormLoader>
			</Page>
		);
	}

	return (
		<Page backLink={backLink}>
			<Elements
				stripe={stripePromise}
				options={{
					clientSecret,
				}}
			>
				<CardForm flow={flow} />
			</Elements>
		</Page>
	);
};

const Page = ({ children, backLink }: { children: ReactNode; backLink: string }) => (
	<>
		<BackButton to={backLink} />
		<Text type="title">
			<FormattedMessage defaultMessage="Enter card details" id="add-credit-card.enter-card-details" />
		</Text>
		{children}
	</>
);

const CardFormLoader = styled.div`
	position: absolute;
	z-index: 0;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CardForm = ({ flow }: { flow: CardFlow }) => {
	const { isLoading, setLoading } = useInterface();
	const stripe = useStripe(),
		elements = useElements();
	const [ready, setReady] = useState(false);
	const [error, setError] = useState<string>();
	return (
		<form
			onSubmit={async (event) => {
				event.preventDefault();
				setLoading(true);
				if (!stripe || !elements) throw new Error('invalid, not yet loaded');
				const returnUrl = new URL(`${location.origin}${links.DEFAULT.CARD_STATUS}`);
				returnUrl.searchParams.append('flow', flow);
				const result = await stripe.confirmSetup({
					elements,
					confirmParams: {
						return_url: returnUrl.toString(),
					},
					redirect: 'always',
				});
				setLoading(false);
				if (result.error) {
					setError(result.error.message);
				}
			}}
		>
			<Spacing $size="m" />
			{!!error && (
				<Alert>
					<AlertTitle>{error}</AlertTitle>
				</Alert>
			)}

			<PaymentElement
				options={{ defaultValues: { billingDetails: { address: { country: 'CA' } } } }}
				onReady={() => setReady(true)}
			/>

			{ready && !isLoading && (
				<Footer>
					<Button htmlType="submit" disabled={!stripe || !elements}>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			)}
		</form>
	);
};
