import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import imageCalendar from 'url:../static/shapes/calendar.png';
import congrats from 'url:../static/shapes/congrats.png';
import creditBuilderHistory from 'url:../static/shapes/credit-builder-history.png';
import learning from 'url:../static/shapes/learning.png';
import imageStep3 from 'url:../static/shapes/onboarding-2.png';
import imageStep2 from 'url:../static/shapes/reschedule-2.png';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { publicConfig } from '../../../../functions/src/shared/config';

import { FormattedCents } from '../../shared/components/formatted-cents';
import { trpc } from '../../shared/trpc/client';

import { Modal } from '../../base-ui/components';

import { AlertError } from '../components/alert-error';
import { ImgPreload } from '../components/img-preload';
import { useInterface } from '../contexts/hooks';
import { useUserData } from '../contexts/user-data-context';
import { CreditBuilderHistoryReportingState } from '../pages/credit-builder-onboarding/credit-builder-onboarding.service';
import { links } from '../Router/paths';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';
import { OnboardingSuccess } from './onboarding-success';

export function CreditBuilderHistoryReporting({ variant }: { variant: 'onboarding' | 'account' }) {
	const location = useLocation();
	const navigate = useNavigate();

	const { setModal, closeModal } = useInterface();
	const { home } = useUserData();
	const intl = useIntl();

	const state = location.state ?? ('step-1' as CreditBuilderHistoryReportingState);

	const utils = trpc.useUtils();
	const historyReportingSignUp = trpc.creditReporting.signUpForHistoryReporting.useMutation({
		onSuccess({ onboardingEvents, home: newHome, paymentId, standAloneHistoryUpsell }) {
			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
			utils.user.home.setData(undefined, newHome);
			if (paymentId) {
				if (!standAloneHistoryUpsell) {
					navigate(
						variant === 'onboarding'
							? links.REGISTRATION.CREDIT_BUILDER.SUBSCRIPTION_TYPE
							: links.ACCOUNT.CREDIT_BUILDER.SUBSCRIPTION_TYPE,
					);
				} else {
					navigate(
						variant === 'onboarding'
							? links.REGISTRATION.CREDIT_BUILDER.SUCCESS
							: links.ACCOUNT.CREDIT_BUILDER.SUCCESS,
					);
				}
			} else {
				navigate(
					variant === 'onboarding' ? links.REGISTRATION.CREDIT_BUILDER.SUCCESS : links.ACCOUNT.DASHBOARD,
				);
			}
		},
	});

	const trackOnboardingEvent = useTrackOnboardingEvent();
	useEffect(() => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingCreditBuilderRentalHistoryUpsellStart });
	}, []);

	const onNotNow = () => {
		setModal(
			<Modal
				type="warning"
				headerText={intl.formatMessage({
					defaultMessage: "Are you sure that you don't want to report past payments?",
					id: 'credit-builder-history-reporting.are-you-sure',
				})}
				contentText={intl.formatMessage({
					defaultMessage:
						'You cannot add your past payments later to your report. You can only take advantage of this now.',
					id: 'credit-builder-history-reporting.cannot-add-later',
				})}
				buttonText={intl.formatMessage({
					defaultMessage: "Yes, I'm sure",
					id: 'credit-builder-history-reporting.yes-im-sure',
				})}
				buttonHandler={() => {
					historyReportingSignUp.mutate(
						{
							enabled: false,
						},
						{
							onSettled() {
								closeModal();
							},
						},
					);
				}}
				altButtonText={intl.formatMessage({
					defaultMessage: "No, let's add past history",
					id: 'credit-builder-history-reporting.no-lets-add-past-history',
				})}
				altButtonHandler={closeModal}
			/>,
		);
	};

	if (state === 'step-2') {
		return (
			<>
				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Build more credit history with past payments"
							id="credit-builder-history-reporting.title"
						/>
					}
					subtitle={
						<>
							<FormattedMessage
								defaultMessage="You can now report up to 24 months of past rent payment history for maximum impact and only for a one time fee of {price}."
								id="credit-builder-history-reporting.subtitle"
								values={{
									price: (
										<FormattedCents
											value={
												home?.creditBuilderPricing?.historyReportingUpsellPriceCents ??
												publicConfig.creditBuilder.historyReportingUpsellPriceCents
											}
										/>
									),
								}}
							/>
						</>
					}
					imageSrc={creditBuilderHistory}
					imageProps={{ padding: '5rem 3rem 0', textAlign: 'center', maxHeight: '28rem' }}
					cta={
						<FormattedMessage
							defaultMessage="Build credit with past payments"
							id="credit-builder-history-reporting.cta"
						/>
					}
					ctaTestId="credit-builder-history-reporting-confirm-button"
					onNext={() =>
						historyReportingSignUp.mutate({
							enabled: true,
						})
					}
					beforeCta={<>{historyReportingSignUp.isError && <AlertError />}</>}
					footerStickyOnMobile
					backButton={false}
					secondaryCta={
						<FormattedMessage
							defaultMessage="No, I don't want to get credit for past payments"
							id="credit-builder-history-reporting.secondary-cta"
						/>
					}
					onSecondaryCta={onNotNow}
				/>

				{/* preload next steps */}
				<ImgPreload src={imageStep2} />
				<ImgPreload src={imageStep3} />
				<ImgPreload src={congrats} />
				<ImgPreload src={learning} />
			</>
		);
	}

	// step 1
	return (
		<>
			<OnboardingSuccess
				title={
					<FormattedMessage
						defaultMessage="More history for better credit."
						id="credit-builder-history-reporting-more-history.title"
					/>
				}
				subtitle={
					<>
						<FormattedMessage
							defaultMessage="Credit history is one of the main factors of great credit score. By including your past rent payments, up to 24 months, you can accelerate the impact of rent payments on your credit."
							id="credit-builder-history-reporting-more-history.subtitle"
						/>
					</>
				}
				imageSrc={imageCalendar}
				imageProps={{ padding: '5rem 3rem 0', textAlign: 'center', maxHeight: '28rem' }}
				cta={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
				ctaTestId="credit-builder-history-step-1-continue-button"
				onNext={() => navigate('', { state: 'step-2' } satisfies { state: CreditBuilderHistoryReportingState })}
			/>
		</>
	);
}
