import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import directDebitOk from 'url:../../static/shapes/connect-bank.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Box, Spacing } from '../../../base-ui/components';

import { useInterface } from '../../contexts/hooks';
import { paths } from '../../Router/paths';
import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { DirectDebitCardCancelModal } from './direct-debit-card-cancel-modal';

export const DirectDebitSetup = () => {
	const navigate = useNavigate();
	const trackEvent = useTrackOnboardingEvent();
	const { setModal } = useInterface();

	function handleNext() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitStart },
			{
				onSuccess: () => {
					navigate(`../${paths.REGISTRATION.DIRECT_DEBIT.CARD_DETAILS}`);
				},
			},
		);
	}
	function handleDecline() {
		setModal(<DirectDebitCardCancelModal />);
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={directDebitOk} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Fast and secure payments"
							id="custom-rent-onboarding.direct-debit-setup.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Paying your initial payment with debit Card is the fastest and safest way to make your payments. No NSF’s, no delays and automated. If added, payment will be processed via debit card when eTransfer is not received."
							id="custom-rent-onboarding.direct-debit-setup.text"
						/>
					}
					cta={
						<FormattedMessage
							defaultMessage="Add my debit card"
							id="custom-rent-onboarding.direct-debit-setup.confirm"
						/>
					}
					secondaryCta={
						<FormattedMessage
							defaultMessage="No, I don't want to pay via debit card"
							id="custom-rent-onboarding.direct-debit-setup.decline"
						/>
					}
					ctaTestId="direct-debit-setup-continue-button"
					onNext={handleNext}
					onSecondaryCta={handleDecline}
				/>
			</Box>
		</>
	);
};
