import { z } from 'zod';

export enum TaskType {
	// Landlord payment tasks
	RentUserResidencyNotVerified = 'rent_user_residency_not_verified',
	RentResidentDeleted = 'rent_resident_deleted',
	RentPmsLedgerMissing = 'rent_pms_ledger_missing',
	RentPmsLedgerBaseRentChargeMissing = 'rent_pms_ledger_base_rent_charge_missing',
	RentPmsLedgerExtraPayment = 'rent_pms_ledger_extra_payment',
	RentPmsLedgerLowBalance = 'rent_pms_ledger_low_balance',
	RentCurrentResidencyDifferent = 'rent_current_residency_different',
	RentLedgerSpecialCaseReview = 'rent_ledger_special_case_review',
	RentMonthDifferent = 'rent_month_different',
	RentAlreadyCoveredThisMonth = 'rent_already_covered_this_month',
	RentLeaseChargeDifferent = 'rent_lease_charge_different',
	RentUpfrontPaymentNotPaid = 'rent_upfront_payment_not_paid',
	RentMissingLandlordPaymentAccount = 'rent_missing_landlord_payment_account',
	NegativeMidMonthCharge = 'negative_mid_month_charge',
	RoommateDoubleCover = 'roommate_double_cover',
	RentToCoverEligibilityArrears = 'rent_to_cover_eligibility_arrears',
	// Credit reporting tasks
	CrPmsDiscrepancy = 'cr_pms_discrepancy',
	CrUnpaidBalance = 'cr_unpaid_balance',
	CrPendingCreditBuilderPayment = 'cr_pending_credit_builder_payment',
	CrPropertyResidentMismatch = 'cr_property_resident_mismatch',
	CrUnexpectedPropertyResidentStatus = 'cr_unexpected_property_resident_status',
	CrCloseTradeline = 'cr_close_tradeline',
	CrMissingPropertyResidentId = 'cr_missing_property_resident_id',
	CrMissingLastPaymentDate = 'cr_missing_last_payment_date',
	CrMissingDateAccountOpened = 'cr_missing_date_account_opened',
	CrDateLastPaymentBeforeDateOpened = 'cr_date_last_payment_before_date_opened',
	CrDifferenceFromLastMonth = 'cr_difference_from_last_month',
	CrUserNotVerified = 'cr_user_not_verified',
	CrManualPms = 'cr_manual_pms',
	CrLocMissingPropertyResidentOnUserRent = 'cr_loc_missing_property_resident_on_user_rent',
	CrDuplicatePropertyResident = 'cr_duplicate_property_resident',
	CrIndirectMissingLandlordEmail = 'cr_indirect_missing_landlord_email',
	// Eligibility
	EligibilityArrears = 'eligibility_arrears',
	ClosedAccountDisconnected = 'closed_account_disconnected',
	RunningTransactionsForClosedRent = 'running_transactions_for_closed_rent',
	// Manual Residency Tasks
	MrLeaseAgreementUploaded = 'mr_lease_agreement_uploaded',

	/** @deprecated */
	RentWithoutResident = 'rent_without_resident',
}

export enum TaskStatus {
	Created = 'created',
	Resolved = 'resolved',
	Done = 'done',
}

export const insertReceiptTaskPayloadSchema = z.object({ insertReceipt: z.boolean() });

export const customRentTaskTypes = [
	TaskType.EligibilityArrears,
	TaskType.RentUserResidencyNotVerified,
	TaskType.RentResidentDeleted,
	TaskType.RentPmsLedgerMissing,
	TaskType.RentPmsLedgerBaseRentChargeMissing,
	TaskType.RentPmsLedgerExtraPayment,
	TaskType.RentPmsLedgerLowBalance,
	TaskType.RentCurrentResidencyDifferent,
	TaskType.RentLedgerSpecialCaseReview,
	TaskType.RentMonthDifferent,
	TaskType.RentAlreadyCoveredThisMonth,
	TaskType.RentLeaseChargeDifferent,
	TaskType.RentUpfrontPaymentNotPaid,
	TaskType.RentMissingLandlordPaymentAccount,
	TaskType.NegativeMidMonthCharge,
	TaskType.RoommateDoubleCover,
	TaskType.RentToCoverEligibilityArrears,
	TaskType.ClosedAccountDisconnected,
	TaskType.RunningTransactionsForClosedRent,
] as const;

export const manualResidencyTaskTypes = [TaskType.MrLeaseAgreementUploaded] as const;

export const frontendTaskStatusObj = z.object({
	frontendTaskStatus: z.enum(['needs-review', 'completed', '']),
});
