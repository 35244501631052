import React, { ReactNode, useRef, useState } from 'react';

import { ModalDialog } from '../../../base-ui/components';

import { Loader } from './Loader/Loader';

type SetModalOptions = {
	onClose?: () => void;
};

type InterfaceState = {
	isLoading: boolean;
	setLoading: (value: boolean) => void;
	setModal: (component: ReactNode, options?: SetModalOptions) => void;
	closeModal: (options?: { onClosed?: () => void }) => void;
};

const initialState: InterfaceState = {
	isLoading: false,
	setLoading: () => {
		return;
	},
	setModal: () => {
		return;
	},
	closeModal: () => {
		return;
	},
};

const InterfaceContext = React.createContext(initialState);

export const InterfaceProvider = ({ children }: { children: ReactNode }) => {
	const [loading, setLoadingState] = useState(false);
	const [modalComponent, setModalComponent] = useState<ReactNode | null>();
	const [modalVisible, setModalVisible] = useState(false);

	const onCloseRef = useRef<() => void>();
	const closeTimeoutRef = useRef<NodeJS.Timeout>();

	const setLoading = (value) => {
		setLoadingState(value);
	};

	const setModal = (component: ReactNode, { onClose }: SetModalOptions = {}) => {
		setModalVisible(true);
		setModalComponent(component);

		if (onClose) onCloseRef.current = onClose;
		// clear close timeout if it exists otherwise we end-up with empty modal when chaining modals after each other
		if (closeTimeoutRef.current) {
			clearTimeout(closeTimeoutRef.current);
			closeTimeoutRef.current = undefined;
		}
	};

	const closeModal = ({ onClosed }: { onClosed?: () => void } = {}) => {
		setModalVisible(false);

		if (onCloseRef.current) {
			onCloseRef.current();
			onCloseRef.current = undefined;
		}

		// setTimeout is there to not hide modal content before the opacity animation finishes
		closeTimeoutRef.current = setTimeout(() => {
			setModalComponent(null);
			onClosed?.();
		}, 300);
	};

	return (
		<InterfaceContext.Provider
			value={{
				isLoading: loading,
				setLoading,
				setModal,
				closeModal,
			}}
		>
			{children}
			<Loader isOpen={loading} />
			<ModalDialog $visible={modalVisible} component={modalComponent} />
		</InterfaceContext.Provider>
	);
};

export const useInterface = () => {
	const context = React.useContext(InterfaceContext);
	if (context === undefined) {
		throw new Error('useInterface must be used within a UIProvider');
	}
	return context;
};
