import React from 'react';
import styled from 'styled-components';
import alc from 'url:./logos/alc.png';
import avana from 'url:./logos/avana.png';
import cityVibe from 'url:./logos/city-vibe.png';
import cogir from 'url:./logos/cogir.png';
import davies from 'url:./logos/davies.png';
import fitzrovia from 'url:./logos/fitzrovia.svg';
import forum from 'url:./logos/forum.png';
import greenwin from 'url:./logos/greenwin.png';
import icm from 'url:./logos/icm.svg';
import laebon from 'url:./logos/laebon.png';
import maddox from 'url:./logos/maddox.png';
import mainstreet from 'url:./logos/mainstreet.png';
import mccor from 'url:./logos/mccor.png';
import newRock from 'url:./logos/newrock.png';
import paragon from 'url:./logos/paragon.png';
import purrPartner from 'url:./logos/purr-partner.svg';
import rhapsody from 'url:./logos/rhapsody.png';
import strategic from 'url:./logos/strategic.png';
import unitii from 'url:./logos/unitii.png';
import vida from 'url:./logos/vida.png';
import zenbase from 'url:./logos/zenbase.svg';
import mail from 'url:./mail.svg';

const logos = {
	'Avenue Living': { logoSrc: alc, width: 50 },
	'Drewlo': { logoSrc: alc, width: 50 },
	'Forum': { logoSrc: forum, width: 70 },
	'ICM': { logoSrc: icm, width: 70, verticalAdjustment: '+ 3px' },
	'Laebon': { logoSrc: laebon, width: 90 },
	'Mainstreet': { logoSrc: mainstreet, width: 85 },
	'Strategic': { logoSrc: strategic, width: 85 },
	'Unitii': { logoSrc: unitii, width: 75 },
	'Vida': { logoSrc: vida, width: 72, verticalAdjustment: '- 2px' },
	// vida living fortress
	'Vida Living': { logoSrc: vida, width: 72, verticalAdjustment: '- 2px' },
	'Fitzrovia': { logoSrc: fitzrovia, width: 34 },
	'Maddox': { logoSrc: maddox, width: 100 },
	'Greenwin': { logoSrc: greenwin, width: 65, verticalAdjustment: '- 3px' },
	'Davies': { logoSrc: davies, width: 84 },
	'McCOR': { logoSrc: mccor, width: 95 },
	'Rhapsody Living': { logoSrc: rhapsody, width: 85 },
	'DPM': { logoSrc: davies, width: 84 },
	'Cogir': { logoSrc: cogir, width: 71 },
	'Avana': { logoSrc: avana, width: 71 },
	'City Vibe': { logoSrc: cityVibe, width: 86 },
	'Paragon Living': { logoSrc: paragon, width: 86 },

	'Zenbase': { logoSrc: zenbase, width: 42 },
	'NewRock Properties': { logoSrc: newRock, width: 90 },
	'Purr Partner': { logoSrc: purrPartner, width: 50, horizontalAdjustment: '+ 2px' },
};

const StyledItem = styled.div`
	max-width: 110px;

	p {
		font-weight: 700;
		margin-top: 16px;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #0a6375;
	}
`;

const Circle = styled.div<{
	$logoSrc: string;
	$width: number;
	$horizontalAdjustment?: number;
	$verticalAdjustment?: number;
}>`
	width: 110px;
	height: 110px;
	border-radius: 50%;
	background-color: white;
	box-shadow: 0px 5px 20px rgba(23, 23, 23, 0.08);

	display: flex;
	align-items: center;
	justify-content: center;

	background-image: ${({ $logoSrc }) => `url(${$logoSrc})`};
	background-repeat: no-repeat;
	background-size: ${({ $width }) => `${$width}px auto`};
	background-position: ${({ $horizontalAdjustment, $verticalAdjustment }) =>
		`calc(50% ${$horizontalAdjustment ?? '+ 0px'}) calc(50% ${$verticalAdjustment ?? '+ 0px'})`};

	&:after {
		content: ' ';
		width: 100px;
		height: 100px;
		border-radius: 50%;

		border: 1px solid rgba(229, 229, 229, 0.7);
	}
`;

const Item = ({
	logo: landlordName,
	showName,
	className,
}: {
	logo: string;
	showName?: boolean;
	className?: string;
}) => {
	if (!(landlordName in logos)) return null;

	const { logoSrc, width, horizontalAdjustment, verticalAdjustment } = logos[landlordName];

	return (
		<StyledItem className={className}>
			<Circle
				$logoSrc={logoSrc}
				$width={width}
				$horizontalAdjustment={horizontalAdjustment}
				$verticalAdjustment={verticalAdjustment}
			/>
			{showName && <p>{landlordName}</p>}
		</StyledItem>
	);
};

type Props = {
	propertyManagerName: string;
	showZenbase?: boolean;
	showMail?: boolean;
	className?: string;
};

export const LandlordConnectedLogo = ({ propertyManagerName, showZenbase, showMail, className }: Props) => {
	if (!showZenbase) {
		return <Item className={className} logo={propertyManagerName} showName={false} />;
	}

	return (
		<Wrapper className={className}>
			<Item logo={propertyManagerName} showName />
			{propertyManagerName in logos && <ItemsDivider showMail={showMail} />}
			<Item logo="Zenbase" showName />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
`;

const ItemsDividerBox = styled.div`
	position: relative;

	& > svg {
		margin: 54px 5px 0;
	}
`;

const ItemsDivider = ({ showMail }: { showMail?: boolean }) => {
	return (
		<ItemsDividerBox>
			<svg width="104" height="3" viewBox="0 0 104 3" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g opacity="0.2">
					<rect x="0.830505" y="0.644073" width="11.3559" height="2.27119" rx="1" fill="#5D5D5D" />
					<rect x="19" y="0.644073" width="11.3559" height="2.27119" rx="1" fill="#5D5D5D" />
					<rect x="37.1695" y="0.644073" width="11.3559" height="2.27119" rx="1" fill="#5D5D5D" />
					<rect x="55.339" y="0.644073" width="11.3559" height="2.27119" rx="1" fill="#5D5D5D" />
					<rect x="73.5085" y="0.644073" width="11.3559" height="2.27119" rx="1" fill="#5D5D5D" />
					<rect x="91.678" y="0.644073" width="11.3559" height="2.27119" rx="1" fill="#5D5D5D" />
				</g>
			</svg>

			{showMail && <MailImg />}
		</ItemsDividerBox>
	);
};

const MailImg = styled.img.attrs({ src: mail })`
	width: 62px;
	position: absolute;
	left: 50%;
	margin-left: -31px;
	top: 25px;
`;
