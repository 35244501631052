import React, { ReactNode, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { FormatXMLElementFn } from 'intl-messageformat';
import { IntlProvider } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { UserLocale } from '../../../../../functions/src/shared/user';

import { Loader } from '../../../base-ui/components';

import { Highlight, HighlightTeal } from '../../components/highlighted';
import { auth } from '../../contexts/Firebase/firebaseApi';
import { LANGUAGE_KEY, localesProviderService } from './locales-provider-service';
import { LocalesZodSetup } from './locales-zod-setup';
import { useLocaleFromStorage } from './use-locale-from-storage';
import { useLocaleMessages } from './use-locale-messages';

/**
 * This hook is responsible for resolving the current locale.
 * It will use the following order:
 * 1. Locale from the URL
 * 2. Locale from the storage
 * 3. Locale from the user
 * 4. Default locale
 *
 *  Current locale is stored in the storage, so it can be used even if URL and User is not available (e.g. on the login page).
 *  * @returns The current locale
 */
const useCurrentLocale = () => {
	const [locale, setLocale] = useState<UserLocale>();

	const [storageLocale, setStorageLocale] = useLocaleFromStorage();
	const [searchParams] = useSearchParams();
	const searchParamsLocale = searchParams.get(LANGUAGE_KEY);

	const { data: messages, isLoading: isMessagesLoading } = useLocaleMessages(locale);

	const updateLocale = (userSettingsLocale?: UserLocale) => {
		const resolvedLocale = localesProviderService.resolveLocale({
			storageLocale,
			userSettingsLocale,
			locale,
			searchParamsLocale,
		});
		// otherwise we would override the locale from BE when loaded
		if (userSettingsLocale) {
			setStorageLocale(resolvedLocale);
		}
		setLocale(resolvedLocale);

		if (resolvedLocale) {
			auth.languageCode = resolvedLocale.split('-')?.[0] || null;
		}

		if (window.zE && resolvedLocale) {
			window.zE('messenger:set', 'locale', resolvedLocale as string);
		}
		return resolvedLocale;
	};

	function setUserSettingsLocale(newLocale: UserLocale) {
		return updateLocale(newLocale);
	}

	useEffect(() => {
		updateLocale();
	}, [locale, storageLocale, searchParamsLocale]);

	return { locale, messages, isMessagesLoading, setUserSettingsLocale };
};

const LocaleContext = React.createContext<{
	setUserSettingsLocale: (locale: UserLocale) => UserLocale | undefined;
} | null>(null);

export const useLocaleContext = () => useContext(LocaleContext);

const richElementFormatters: Record<string, FormatXMLElementFn<ReactNode>> = {
	br: () => <br />,
	strong: (v) => <strong>{v}</strong>,
	b: (v) => <strong>{v}</strong>,
	color: (v) => <HighlightTeal>{v}</HighlightTeal>,
	highlight: (v) => <Highlight>{v}</Highlight>,
};
export const LocalesProvider = ({ children }: { children: ReactNode }) => {
	const { locale, messages, isMessagesLoading, setUserSettingsLocale } = useCurrentLocale();

	if (isMessagesLoading || !locale) {
		return <Loader />;
	}

	return (
		<LocaleContext.Provider
			value={{
				setUserSettingsLocale,
			}}
		>
			<IntlProvider
				locale={locale}
				defaultLocale={locale}
				messages={messages || {}}
				defaultRichTextElements={richElementFormatters}
				onError={(e) => {
					console.error('Error in i18n', e);
					Sentry.captureException(e);
				}}
				onWarn={(e) => {
					Sentry.captureMessage(e, Sentry.Severity.Warning);
				}}
			>
				<LocalesZodSetup />
				{children}
			</IntlProvider>
		</LocaleContext.Provider>
	);
};
