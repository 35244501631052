import React, { ComponentProps } from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export const MasterCardIcon = (props: ComponentProps<typeof Svg>) => (
	<Svg height="1em" viewBox="0 0 49 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect x="3" y="3" width="42.1755" height="27.29" rx="12" fill="#26365F" />
		<rect
			x="1.5"
			y="1.5"
			width="45.1755"
			height="30.29"
			rx="13.5"
			stroke="black"
			strokeOpacity="0.1"
			strokeWidth="3"
		/>
		<path
			d="M26.7028 8.24629L20.0334 8.98734L17.0692 18.621L21.145 25.2904L26.7028 24.9199L31.8902 20.4736L26.7028 8.24629Z"
			fill="#FF5C00"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24.4797 8.42365C21.6002 10.0893 19.6629 13.2027 19.6629 16.7685C19.6629 20.3343 21.6002 23.4476 24.4797 25.1133C23.0627 25.933 21.4176 26.4021 19.6629 26.4021C14.3424 26.4021 10.0292 22.089 10.0292 16.7685C10.0292 11.448 14.3424 7.13484 19.6629 7.13484C21.4176 7.13484 23.0627 7.60397 24.4797 8.42365Z"
			fill="#FC0101"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24.1092 8.42366C26.9887 10.0894 28.926 13.2027 28.926 16.7685C28.926 20.3343 26.9887 23.4476 24.1092 25.1133C25.5262 25.933 27.1713 26.4021 28.926 26.4021C34.2466 26.4021 38.5597 22.089 38.5597 16.7685C38.5597 11.448 34.2466 7.13484 28.926 7.13484C27.1713 7.13484 25.5262 7.60398 24.1092 8.42366Z"
			fill="#FCD401"
		/>
	</Svg>
);
