import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { publicConfig } from '../../../../functions/src/shared/config';

import { getEnvironment } from '../../shared/environment';
import { trpc } from '../../shared/trpc/client';

import { Button, Footer, Loader, Spacing, Text } from '../../base-ui/components';

import { BackButton } from '../components/back-button';
import { useUserData } from '../contexts/hooks';
import { CardFlow, cardFlowProps } from './unified-payments-onboarding/2-2-0-select-payment-method';

export const CardStatus = () => {
	const {
		handlers: { forceRefetch },
	} = useUserData();
	const location = useLocation();
	const navigate = useNavigate();
	const [stripePromise] = useState(() => loadStripe(publicConfig.stripe[getEnvironment()].publicKey));
	const search = new URLSearchParams(location.search);
	const flow = search.get('flow') as CardFlow;
	const { successLink } = cardFlowProps(flow);

	const confirmAccount = trpc.user.paymentCards.confirmStripeCard.useMutation();

	const [result, setResult] = useState<'success'>();
	const [mask, setMask] = useState<string>();

	const loadIntentStatus = async () => {
		const clientSecret = search.get('setup_intent_client_secret');
		const stripe = await stripePromise;
		if (!clientSecret || !stripe) return;

		const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);
		switch (setupIntent?.status) {
			case 'succeeded':
				const { mask: newMask } = await confirmAccount.mutateAsync({
					setupIntentId: setupIntent.id,
				});
				setMask(newMask);
				setResult('success');
				break;

			case 'requires_payment_method':
				// Redirect your user back to your payment page to attempt collecting
				// payment again
				// 'Failed to process payment details. Please try another payment method.');
				break;

			default:
			// todo
		}
	};

	useEffect(() => {
		loadIntentStatus();
	}, []);

	const handleContinue = async () => {
		await forceRefetch();
		navigate(successLink);
	};

	if (result === 'success') {
		return (
			<>
				<Text type="title">
					<FormattedMessage defaultMessage="Card saved" id="card-status.card-saved" />
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage
						defaultMessage="Card ending with {mask} was saved successfully."
						id="card-status.card-ending"
						values={{ mask }}
					/>
				</Text>
				<Spacing $size="l" />

				<Footer>
					<Button onClick={() => handleContinue()}>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</>
		);
	}

	return (
		<>
			<BackButton />
			<Text type="title">
				<FormattedMessage defaultMessage="Saving payment card" id="card-status.saving-payment-card" />
			</Text>
			<CardFormLoader>
				<Loader />
			</CardFormLoader>
		</>
	);
};

const CardFormLoader = styled.div`
	position: absolute;
	z-index: 0;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;
