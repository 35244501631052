import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';

import { getEnvironment } from '../../../shared/environment';

import { Alert, AlertTitle, Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { StripeTestData } from './stripe-test-data';

const showStripeTestData = getEnvironment() !== 'production';

const useFormState = ({ onSuccess }: { onSuccess: (paymentMethodId: string) => void }) =>
	useMutation({
		onSuccess,
		mutationFn: async ({ stripe, elements }: { stripe: Stripe | null; elements: StripeElements | null }) => {
			if (!stripe || !elements) throw new Error('invalid, not yet loaded');

			// Trigger form validation and wallet collection
			const { error: submitError } = await elements.submit();
			if (submitError) {
				throw new Error(submitError.message ?? 'Payment details are not valid!');
			}

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				elements,
				params: { billing_details: {} },
			});
			if (error) {
				throw new Error(error.message ?? 'Error when processing payment details!');
			}

			return paymentMethod.id;
		},
	});

export const StripePaymentMethodForm = ({
	onPaymentMethodCreated,
}: {
	onPaymentMethodCreated: (paymentMethodId: string) => void;
}) => {
	const stripe = useStripe(),
		elements = useElements();
	const [ready, setReady] = useState(false);
	const formSubmitState = useFormState({
		onSuccess: onPaymentMethodCreated,
	});

	async function handleFormSubmit(event) {
		event.preventDefault();
		formSubmitState.mutate({
			stripe,
			elements,
		});
	}

	return (
		<form onSubmit={handleFormSubmit}>
			<Spacing $size="m" />
			{!!formSubmitState.error && (
				<Alert>
					<AlertTitle>{formSubmitState.error.toString().replace('Error:', '')}</AlertTitle>
				</Alert>
			)}

			<PaymentElement
				options={{ defaultValues: { billingDetails: { address: { country: 'CA' } } } }}
				onReady={() => setReady(true)}
			/>

			{ready && !formSubmitState.isLoading && (
				<Footer>
					<Button
						htmlType="submit"
						disabled={!stripe || !elements}
						testId="onboarding-cb-card-details-confirm-button"
					>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			)}

			{showStripeTestData && (
				<>
					<Spacing $size="xl" />
					<Text type="title">
						<FormattedMessage defaultMessage="Test Data" id="stripe-payment-method-form.test-data-title" />
					</Text>
					<Spacing $size="s" />
					<StripeTestData />
				</>
			)}
		</form>
	);
};
