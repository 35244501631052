import { useEffect } from 'react';

// https://stackoverflow.com/a/34425083
export const useGlobalScript = (scriptId: string, url: string) => {
	useEffect(() => {
		if (document.getElementById(scriptId)) {
			// already loaded
			return;
		}

		const script = document.createElement('script');
		script.id = scriptId;
		script.src = url;
		script.async = true;
		document.body.appendChild(script);

		//  no cleanup, once script is added it evaluated and available in global scope
	}, [url]);
};
