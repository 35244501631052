import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { amountToCents, centsToAmount } from '../../../../../functions/src/shared/monetary';
import { RentMonth } from '../../../../../functions/src/shared/rent-month';

import { trpc } from '../../../shared/trpc/client';
import { preprocessFloat } from '../../../shared/zod-schemas';

import {
	Alert,
	AlertText,
	AlertTitle,
	Button,
	Footer,
	FormInput,
	PositiveModal,
	Spacing,
	Text,
	WarningModal,
} from '../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../base-ui/components/Alert/Alert';

import { BackButton } from '../../components/back-button';
import { useInterface, useUserData } from '../../contexts/hooks';
import { links } from '../../Router/paths';

const schema = z.object({
	amount: preprocessFloat(z.number().gt(0).lt(100_000)),
});

type FormData = z.infer<typeof schema>;

export const ChangeRentAmount = () => {
	const {
		handlers: { forceRefetch },
	} = useUserData();
	const { setModal, closeModal } = useInterface();
	const navigate = useNavigate();
	const intl = useIntl();

	const getConfig = trpc.user.customRent.getConfig.useQuery(undefined, {
		onSuccess(loadedConfig) {
			form.setValue('amount', centsToAmount(loadedConfig?.rentAmountCents ?? 0));
		},
	});
	const config = getConfig.data;

	const form = useForm<FormData>({
		resolver: zodResolver(schema),
		defaultValues: { amount: centsToAmount(config?.rentAmountCents ?? 0) },
	});

	const saveConfig = trpc.user.customRent.saveConfigAndEnable.useMutation({
		async onSuccess() {
			await forceRefetch();
			closeModal();
			navigate(links.ACCOUNT.SETTINGS);
		},
	});

	const submit = async ({ amount }: FormData) => {
		if (!config) throw new Error('config must be set');
		saveConfig.mutate({ ...config, rentAmountCents: amountToCents(amount) });
	};

	const openConfirmationModal = (data: FormData) => {
		if (!config) throw new Error('config should been set');
		const { amount } = data;

		const formattedAmount = <FormattedNumber value={amount} style="currency" currency="CAD" />;
		const monthName = (
			<FormattedDate value={RentMonth.fromDbParams(config.rentMonth).firstDayAsDate()} month="long" />
		);

		const content = (
			<>
				<FormattedMessage
					defaultMessage="Starting from {monthName} your rent will be {formattedAmount}. You can message the Zenbase team if you have any questions regarding the timing of the change."
					id="change-rent-amount.confirmation-modal-content"
					values={{ monthName, formattedAmount }}
				/>
			</>
		);

		if (amount < 500 || amount > 2500) {
			setModal(
				<WarningModal
					headerText={intl.formatMessage({
						defaultMessage: 'Please Confirm',
						id: 'change-rent-amount.positive-modal-header',
					})}
					contentText={
						<>
							{intl.formatMessage(
								{
									defaultMessage:
										'Are you sure you want to set your rent amount to {formattedAmount}?',
									id: 'change-rent-amount.warning-modal-content',
								},
								{
									formattedAmount,
								},
							)}
							<br />
							<br />
							{content}
						</>
					}
					buttonText={intl.formatMessage({
						defaultMessage: 'Confirm',
						id: 'common.confirm',
					})}
					buttonHandler={() => {
						submit(data);
					}}
					altButtonText={intl.formatMessage({
						defaultMessage: 'Cancel',
						id: 'common.cancel',
					})}
					altButtonHandler={() => {
						closeModal();
						navigate(links.ACCOUNT.SETTINGS);
					}}
				/>,
			);
		} else {
			setModal(
				<PositiveModal
					headerText={intl.formatMessage({
						defaultMessage: 'Please Confirm',
						id: 'change-rent-amount.positive-modal-header',
					})}
					contentText={content}
					buttonText={intl.formatMessage({
						defaultMessage: 'Confirm',
						id: 'common.confirm',
					})}
					buttonHandler={() => {
						submit(data);
					}}
					altButtonText={intl.formatMessage({
						defaultMessage: 'Cancel',
						id: 'common.cancel',
					})}
					altButtonHandler={() => {
						closeModal();
						navigate(links.ACCOUNT.SETTINGS);
					}}
				/>,
			);
		}
	};

	if (getConfig.isError) {
		return <UnexpectedErrorAlert />;
	}

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(openConfirmationModal)}>
				<BackButton />
				<Text type="title">
					{intl.formatMessage({
						defaultMessage: 'Enter your rent amount',
						id: 'change-rent-amount.enter-rent-amount-title',
					})}
				</Text>
				<Text>
					<FormattedMessage defaultMessage="Total monthly rent" id="change-rent-amount.total-monthly-rent" />
				</Text>

				<Spacing $size="m" />

				{saveConfig.isError && (
					<Alert>
						<AlertTitle>
							{intl.formatMessage({
								defaultMessage: 'Error',
								id: 'common.error',
							})}
						</AlertTitle>
						<AlertText>
							{intl.formatMessage({
								defaultMessage: 'Updating rent amount failed.',
								id: 'change-rent-amount.error-alert-content',
							})}
						</AlertText>
					</Alert>
				)}

				<FormInput
					name="amount"
					placeholder={intl.formatMessage({
						defaultMessage: 'Rent amount',
						id: 'account-settings.rent-amount.label',
					})}
					testId="select-rent-input-amount"
					inputMode="decimal"
					type="number"
					prefix="$"
				/>
				<Footer>
					<Button disabled={getConfig.isLoading || saveConfig.isLoading} htmlType="submit">
						{intl.formatMessage({ defaultMessage: 'Continue', id: 'common.continue' })}
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
