import { BusinessEventType } from '../../../../../functions/src/shared/business-events';
import { ManualResidencyLeaseAgreementStatus, UserPmsResidencyType } from '../../../../../functions/src/shared/pms';

import { RouterOutput } from '../../../shared/trpc/client';

export type CreditBuilderSetupState =
	| 'step-2'
	| 'step-3'
	| 'step-4'
	| 'step-indirect-housing-provider-1' // housing provider information (don't have payment portal)
	| 'step-indirect-select-rent-amount-2' // select rent amount
	| 'step-indirect-lease-dates-3' // lease dates
	| 'step-indirect-lease-agreement-5-1' // lease agreement upload
	| 'step-confirm-rent-reporting-5'; // confirm rent reporting

export type CreditBuilderHistoryReportingState = 'step-1' | 'step-2';

type Home = RouterOutput['user']['home'];
type CreditSubject = RouterOutput['creditReporting']['getUserInfo'];

export const creditBuilderOnboardingService = {
	resolveState({
		onboardingEvents,
		isReferral,
		home,
		creditSubject,
	}: {
		onboardingEvents: RouterOutput['user']['listOnboardingEvents'];
		isReferral?: boolean;
		home?: Home | null;
		creditSubject?: CreditSubject | null;
	}): CreditBuilderSetupState | undefined {
		if (home?.residency?.type === UserPmsResidencyType.Indirect && !isReferral) {
			const residency = home.residency;
			// TODO leaseagr: add manual landlord id to manual_residencies and check it here
			if (!(BusinessEventType.OnboardingHousingProviderStart in onboardingEvents)) {
				return undefined;
			}
			if (!(BusinessEventType.OnboardingHousingProviderEnd in onboardingEvents)) {
				return 'step-indirect-housing-provider-1';
			}
			if (!residency.manualResidencyRentAmountCents) {
				return 'step-indirect-select-rent-amount-2';
			}

			if (!residency.residentStartDate || !residency.residentEndDate) {
				return 'step-indirect-lease-dates-3';
			}
			if (
				creditSubject?.leaseAgreementUploadPending &&
				residency.manualResidencyLeaseAgreementStatus === ManualResidencyLeaseAgreementStatus.Pending
			) {
				return 'step-indirect-lease-agreement-5-1';
			}
			if (!creditSubject) {
				return 'step-confirm-rent-reporting-5';
			}
		}

		if (BusinessEventType.OnboardingLeaseAgreementUploadEnd in onboardingEvents) {
			return 'step-confirm-rent-reporting-5';
		}
		if (BusinessEventType.OnboardingLeaseAgreementUploadStart in onboardingEvents) {
			return 'step-indirect-lease-agreement-5-1';
		}
		if (BusinessEventType.OnboardingLeaseAgreementUploadStart in onboardingEvents) {
			return 'step-indirect-lease-agreement-5-1';
		}
		if (BusinessEventType.OnboardingPaymentPortalEnd in onboardingEvents) {
			return 'step-confirm-rent-reporting-5';
		}
		if (BusinessEventType.OnboardingIndirectLeaseDatesEnd in onboardingEvents) {
			if (isReferral) {
				return 'step-confirm-rent-reporting-5';
			}
			return 'step-indirect-lease-agreement-5-1';
		}
		if (BusinessEventType.OnboardingIndirectSelectRentAmountEnd in onboardingEvents) {
			return 'step-indirect-lease-dates-3';
		}
		if (BusinessEventType.OnboardingHousingProviderEnd in onboardingEvents) {
			return 'step-indirect-select-rent-amount-2';
		}
		if (BusinessEventType.OnboardingHousingProviderStart in onboardingEvents) {
			return 'step-indirect-housing-provider-1';
		}

		return undefined;
	},
};
