import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import uploadStatements from 'url:../../static/shapes/upload-statements.png';

import { InviteType } from '../../../../../functions/src/shared/partner';

import { trpc } from '../../../shared/trpc/client';

import { Button, Spacing, Text } from '../../../base-ui/components';
import { LoaderCentered } from '../../../base-ui/components/Loader/Loader';
import { Center } from '../../../base-ui/utils';

import { AlertError } from '../../components/alert-error';
import { links } from '../../Router/paths';
import { setInvite } from '../../utils/invite-storage';

const ImagePanel = styled.div`
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
	top: 8rem;
`;

export const Handover = () => {
	const { inviteId } = useParams<{ inviteId: string }>();
	const [searchParams] = useSearchParams();
	const secret = searchParams.get('s') ?? undefined;

	const navigate = useNavigate();
	const intl = useIntl();

	useEffect(() => {
		if (!inviteId) return;
		setInvite(inviteId, secret);
	}, [inviteId]);

	if (!inviteId) throw new Error('inviteId url param missing');

	const { data, isLoading, isError } = trpc.public.getInviteDetails.useQuery(
		{ invite: inviteId, secret: secret ?? '' },
		{ refetchOnWindowFocus: false },
	);

	if (isLoading) {
		return <LoaderCentered />;
	}

	if (isError) {
		return <AlertError />;
	}

	if (data.status === 'found' && data.type !== InviteType.CreditBuilderHandover) {
		navigate(links.REGISTRATION.ROOT);
		return null;
	}

	return (
		<Center>
			<ImagePanel>
				<img src={uploadStatements} />

				<Text type="title" center style={{ marginTop: '3rem' }}>
					<FormattedMessage
						defaultMessage="Almost ready to start building credit with rent payments!"
						id="credit-builder-handover.invited-to-zenbase"
					/>
				</Text>
				<Spacing $size="m" />
				<Text center>
					<FormattedMessage
						defaultMessage="We have received your lease details from {referralPartnerName} and linked them to your sign up so that you can start building credit with your rent payments even faster!"
						id="credit-builder-handover.subtitle"
						values={{
							referralPartnerName:
								'referralPartnerName' in data && data.referralPartnerName
									? data.referralPartnerName
									: intl.formatMessage({
											defaultMessage: 'our partner',
											id: 'credit-builder-handover.referral-partner-name-fallback',
										}),
						}}
					/>
				</Text>
				<Spacing $size="m" />
			</ImagePanel>

			<Button linkTo={links.REGISTRATION.ROOT}>
				<FormattedMessage defaultMessage="Continue" id="common.continue" />
			</Button>
		</Center>
	);
};
