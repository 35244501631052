import React, { useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { To } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';

import { publicConfig } from '../../../../../functions/src/shared/config';
import { canadianProvinces, CanadianProvincialCodes } from '../../../../../functions/src/shared/state-codes';
import { FeatureSwitch } from '../../../../../functions/src/shared/user';

import { getEnvironment } from '../../../shared/environment';
import { useMyZenbaseLinks } from '../../../shared/my-zenbase-links';
import { trpc } from '../../../shared/trpc/client';

import { Anchor, Button, Footer, FormInput, Spacing, Text } from '../../../base-ui/components';

import { FormAutoSuggestInput } from '../../components/form-auto-suggest-input';
import { useUserFeature } from '../../contexts/user-data-context';
import { useGlobalScript } from '../../utils/hooks/use-global-script';
import { provincialCodesService } from '../../utils/provincial-codes-service';
import { AddDebitCardLoading } from './add-debit-card-loading';
import { AddDebitCardWrapper } from './add-debit-card-wrapper';
import { useDebitCard } from './use-debit-card';

const StyledAnchor = styled(Anchor)`
	font-size: 1.6rem;
	line-height: 2.4rem;
`;

export const AddDebitCard = (props: { successLink: To; backLink: string; abortText: string }) => {
	const unifiedPayments = useUserFeature(FeatureSwitch.UnifiedPayments);

	const cardHolderDefaults = trpc.user.paymentCards.defaultCardholderDetails.useQuery(undefined, {
		staleTime: Infinity, // trust cached data from previous step
	});
	const intl = useIntl();
	const myZenbaseLinks = useMyZenbaseLinks();

	const cardDetailsSchema = useMemo(
		() =>
			z.object({
				terms: z.literal(true, {
					errorMap: () => ({
						message: intl.formatMessage({
							defaultMessage: 'Please accept terms and conditions',
							id: 'add-debit-card.please-accept-terms-and-conditions',
						}),
					}),
				}),
				city: z.string().min(1),
				addressLine1: z.string().min(1),
				postalCode: z.preprocess(
					(str) => {
						const cleaned = String(str)
							.toUpperCase()
							.trim()
							// remove all spaces and dashes
							.replace(/[-\s]+/g, '');
						// one space after 3rd character
						return `${cleaned.slice(0, 3)} ${cleaned.slice(3)}`;
					},
					z.string().regex(/^[A-Z]\d[A-Z]\s\d[A-Z]\d$/, {
						message: intl.formatMessage({
							defaultMessage: 'Please provide valid postal code in format "A1A 1A1"',
							id: 'add-debit-card.please-provide-valid-postal-code',
						}),
					}),
				),

				state: z.enum(canadianProvinces, {
					errorMap: () => ({
						message: intl.formatMessage({
							defaultMessage: 'Please choose a province',
							id: 'add-debit-card.please-choose-a-province',
						}),
					}),
				}),
				country: z.literal('Canada').default('Canada'),
			}),
		[intl],
	);

	const form = useForm<z.infer<typeof cardDetailsSchema>>({
		resolver: zodResolver(cardDetailsSchema),
		defaultValues: {
			// use city & state is prefilled from the unit address
			city: cardHolderDefaults.data?.city ?? '',
			state: cardHolderDefaults.data?.state ?? 'AB',
			country: 'Canada',
		},
	});

	const handlePrefill = () => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		form.reset(cardHolderDefaults.data);
	};
	const { handleSubmit, backLink, isLoading } = useDebitCard(props);

	useGlobalScript('zumrails-sdk-script', publicConfig.zumrails[getEnvironment()].jsSdkUrl);

	if (cardHolderDefaults.isInitialLoading) {
		return <AddDebitCardLoading backLink={backLink} />;
	}

	return (
		<AddDebitCardWrapper backLink={backLink}>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					<Text>
						<FormattedMessage
							defaultMessage="Please enter the address that your bank has on file for your card that you will use for rent payments."
							id="add-debit-card.please-enter-the-address"
						/>
					</Text>

					<Spacing $size="s" />

					<FormInput
						name="addressLine1"
						placeholder={intl.formatMessage({
							defaultMessage: 'Address',
							id: 'add-debit-card.address',
						})}
					/>
					<FormInput
						name="postalCode"
						placeholder={intl.formatMessage({
							defaultMessage: 'Postal Code',
							id: 'add-debit-card.postal-code',
						})}
					/>
					<FormInput
						name="city"
						placeholder={intl.formatMessage({
							defaultMessage: 'City',
							id: 'address.city',
						})}
					/>

					<FormAutoSuggestInput
						name="state"
						label={intl.formatMessage({ defaultMessage: 'Province', id: 'add-debit-card.province' })}
						testId="add-debit-card-state"
						options={Object.keys(CanadianProvincialCodes)}
						getOptionLabel={(value) =>
							value ? `${value} - ${provincialCodesService.translateCanadaProvince(intl, value)}` : ''
						}
					/>

					<FormInput
						name="terms"
						type="checkbox"
						label={
							unifiedPayments ? (
								// TOS link todo
								<FormattedMessage
									defaultMessage="I agree with the terms and conditions of the pre-authorized debit payment plan."
									id="add-debit-card.agree-with-terms-and-conditions"
								/>
							) : (
								<>
									<FormattedMessage
										defaultMessage="I allow to debit from/credit to my bank account as agreed upon and in compliance with the"
										id="add-debit-card.i-allow-to-debit"
									/>{' '}
									<StyledAnchor target="_blank" href={myZenbaseLinks.terms}>
										<FormattedMessage
											defaultMessage="terms and conditions"
											id="add-debit-card.terms-and-conditions"
										/>
									</StyledAnchor>
									.
								</>
							)
						}
					/>

					<Footer>
						<Button htmlType="submit" disabled={isLoading}>
							<FormattedMessage defaultMessage="Continue" id="common.continue" />
						</Button>
					</Footer>
				</form>
			</FormProvider>
			{getEnvironment() === 'local' && (
				<Text>
					<StyledAnchor onClick={handlePrefill}>
						local only - prefill {cardHolderDefaults.data?.addressLine1}, {cardHolderDefaults.data?.city}{' '}
					</StyledAnchor>
				</Text>
			)}
		</AddDebitCardWrapper>
	);
};
