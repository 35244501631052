import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Center, Spacing, Text } from '../../../base-ui/components';

import { useInterface } from '../../contexts/hooks';
import CreditBuilderIcon from '../../static/shapes/credit-builder-teal.svg';

export const CreditBuilderPrivacyModal = () => {
	const { closeModal } = useInterface();

	return (
		<Center data-testid="credit-builder-privacy-modal">
			<img src={CreditBuilderIcon} width={80} />
			<Spacing $size="s" />

			<Text type="title-ternary">
				<FormattedMessage
					defaultMessage="Your data is secure with us"
					id="credit-builder-privacy-modal.title"
				/>
			</Text>

			<Text type="small" center>
				<FormattedMessage
					defaultMessage="Your personal information including the information you provide to Zenbase and required information disclosed by your landlord will be processed to report your rent payments to credit bureaus and build your credit history."
					id="credit-builder-privacy-modal.description"
				/>
			</Text>

			<Spacing $size="m" />

			<Button onClick={closeModal}>
				<FormattedMessage defaultMessage="Ok" id="123" />
			</Button>
		</Center>
	);
};
