import React from 'react';
import styled from 'styled-components';

const Step1BackgroundSvg = styled.svg`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: -1;
`;

export const CreditBuilderStep1Background = () => {
	return (
		<Step1BackgroundSvg viewBox="0 0 375 204" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M177.683 191.169C133.823 170.581 61.0943 170.482 0 187.096L0.000420615 0L375 9.05991e-06V164.859C340.553 181.967 249.56 224.908 177.683 191.169Z"
				fill="url(#paint0_linear_417_27)"
				fillOpacity="0.8"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_417_27"
					x1="187.5"
					y1="0"
					x2="187.5"
					y2="192.605"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFEFE4" />
					<stop offset="1" stopColor="#FFEFE4" stopOpacity="0" />
				</linearGradient>
			</defs>
		</Step1BackgroundSvg>
	);
};
