import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Route, Routes } from 'react-router-dom';

import { Variant } from '../contexts/BankOnboardingContext/BankOnboardingContext';
import {
	CustomRentOnboardingAwaitingTransactions,
	CustomRentOnboardingCannotValidateEarnings,
	CustomRentOnboardingConnectBank,
	EnterBankDetails,
	StartVerification,
} from '../pages';
import { MicroDepositPending } from '../pages/manual-bank-onboarding/micro-deposit-pending';
import { SelectPaymentMethod } from '../pages/unified-payments-onboarding/2-2-0-select-payment-method';
import { OnboardingConnectCard } from '../pages/unified-payments-onboarding/2-2-1-connect-card';
import { AddCreditCard } from '../pages/unified-payments-onboarding/2-2-2-add-credit-card';
import { AddDebitCard } from '../pages/unified-payments-onboarding/2-2-2-add-debit-card';
import { NavigateParams } from '../utils/react-router';
import { links, paths } from './paths';
import { BankOnboardingWrapper } from './RouteWrappers';

export const BankConnectionRoutes = ({
	links: l,
	paths: p,
	onCompletedNavigateParams,
	variant,
}: {
	links: typeof links.REGISTRATION.BANK;
	paths: typeof paths.REGISTRATION.BANK;
	onCompletedNavigateParams?: NavigateParams;
	variant: Variant;
}) => {
	const intl = useIntl();

	return (
		<Routes>
			<Route
				element={
					<BankOnboardingWrapper
						links={l}
						onCompletedNavigateParams={onCompletedNavigateParams}
						variant={variant}
					/>
				}
			>
				<Route path={p.CONNECT_BANK} element={<CustomRentOnboardingConnectBank />} />
				<Route path={p.CONNECT_CARD} element={<OnboardingConnectCard />} />
				<Route
					path={p.ADD_DEBIT_CARD}
					element={
						<AddDebitCard
							backLink={l.SELECT_PAYMENT_METHOD}
							abortText={intl.formatMessage({
								defaultMessage: 'Select different payment method',
								id: 'add-debit-card-abort-text',
							})}
							successLink={links.REGISTRATION.CONFIGURE.CONFIRM_CUSTOM_RENT}
						/>
					}
				/>
				<Route path={p.ADD_CREDIT_CARD} element={<AddCreditCard flow="onboarding" />} />
				<Route
					path={p.AWAITING_TRANSACTIONS}
					element={
						<CustomRentOnboardingAwaitingTransactions
							titleText={
								<FormattedMessage
									defaultMessage="Your account is almost set up"
									id="awaiting-transactions-title"
								/>
							}
							connectAgainLink={l.CONNECT_BANK}
							connectManuallyLink={l.MANUAL_ENTER_BANK_DETAILS}
						/>
					}
				/>
				<Route
					path={p.CANNOT_VALIDATE_EARNINGS}
					element={<CustomRentOnboardingCannotValidateEarnings reconnectBankLink={l.CONNECT_BANK} />}
				/>

				<Route path={p.MANUAL_ENTER_BANK_DETAILS} element={<EnterBankDetails />} />
				<Route path={p.MANUAL_START_VERIFICATION} element={<StartVerification />} />
				<Route path={p.MANUAL_PENDING_VERIFICATION} element={<MicroDepositPending />} />

				<Route path={p.SELECT_PAYMENT_METHOD} element={<SelectPaymentMethod />} />
			</Route>
		</Routes>
	);
};
