import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { trpc } from '../../../shared/trpc/client';

import { useUserData } from '../../contexts/user-data-context';
import {
	createCreditBuilderCheckoutLink,
	CreditBuilderSuccess,
	getPaymentFromGetInfo,
} from '../../templates/credit-builder-success';

export const CreditBuilderAccountSuccess = () => {
	const navigate = useNavigate();
	const { hasRentReportingEnabled } = useUserData();
	const { data: info } = trpc.creditReporting.getUserInfo.useQuery();
	const { payment } = getPaymentFromGetInfo('account', info);

	useEffect(() => {
		if (!info) return;

		if (hasRentReportingEnabled && info.paidByResident && info.unpaid) {
			const link = createCreditBuilderCheckoutLink({ payment: payment ?? null, variant: 'account' });
			navigate(link);
			return;
		}
	}, [hasRentReportingEnabled, info]);
	return <CreditBuilderSuccess variant="account" />;
};
