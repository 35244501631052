import React, { ReactNode } from 'react';
import { getWeekOfMonth, isToday } from 'date-fns';
import { FormattedDate } from 'react-intl';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';
import { isWeekendOrHoliday } from '../../../../../functions/src/shared/date-utils';
import { isoDateToJsDate, jsDateToIsoDate } from '../../../../../functions/src/shared/iso-date';
import { PaymentMethod } from '../../../../../functions/src/shared/payment-transaction';
import { RentMonth } from '../../../../../functions/src/shared/rent-month';

import { dataLayer } from '../../../shared/data-layer';
import { trpc } from '../../../shared/trpc/client';

import { DayPicker } from '../../../base-ui/components/DayPicker/DayPicker';
import { Icon } from '../../../base-ui/components/Icon/Icon';

import { useInterface, useUserData } from '../../contexts/hooks';
import { ConfirmFromWeekModal } from './confirm-from-week-modal';
import { ConfirmUpfrontDeadlineModal } from './confirm-upfront-deadline-modal';
import { PayTodayModal } from './pay-today-modal';
import * as S from './payment-date-panel.styles';
import { SelectBusinessDayModal } from './select-business-day-modal';

type PaymentDatePanelProps = {
	date: Date;
	handleUpdateSelect?: (date: Date) => void;
	label?: ReactNode;
	amount?: ReactNode;
	canChangeDateSince: Date;
	canChangeDateUntil?: Date;
	infoText?: ReactNode;
	testIdEdit?: string;
	testIdCalendar?: string;
	isEditable?: boolean;
	rentMonth?: RentMonth;
	needToConfirmFromWeek?: number;
	onSelectDateOpen?: () => void;
	paymentMethod?: PaymentMethod;
	needToConfirmUpfrontDeadlineFrom?: Date;
	paymentTransactionId?: string;
};

export const PaymentDatePanel = ({
	date,
	handleUpdateSelect,
	label,
	amount,
	infoText,
	canChangeDateSince,
	canChangeDateUntil,
	testIdEdit,
	testIdCalendar,
	rentMonth,
	isEditable = true,
	needToConfirmFromWeek,
	onSelectDateOpen,
	paymentMethod,
	needToConfirmUpfrontDeadlineFrom,
	paymentTransactionId,
}: PaymentDatePanelProps) => {
	const { setModal, closeModal } = useInterface();
	const trackEvent = trpc.user.trackEvent.useMutation();
	const { user, home } = useUserData();

	const toggleEdit = () => {
		if (isEditable) {
			dataLayer.push({ event: 'payment_set_date_modal_open' });
			setModal(<EditModal />);

			if (onSelectDateOpen) {
				onSelectDateOpen();
			}
		}
	};

	const handleSelect = (newDate?: Date) => {
		if (newDate) {
			if (isToday(newDate)) {
				return setModal(<PayTodayModal toggleEdit={toggleEdit} headerVariant="now" />);
			}

			if (rentMonth && isoDateToJsDate(rentMonth.firstDayAsIsoDate()) > newDate) {
				return setModal(<PayTodayModal toggleEdit={toggleEdit} headerVariant="early" />);
			}

			if (paymentMethod === PaymentMethod.Eft && isWeekendOrHoliday(newDate)) {
				return setModal(<SelectBusinessDayModal toggleEdit={toggleEdit} />);
			}

			if (needToConfirmFromWeek && getWeekOfMonth(newDate, { weekStartsOn: 1 }) >= needToConfirmFromWeek) {
				dataLayer.push({ event: 'payment_set_date_confirm_week_of_month_modal_open' });

				return setModal(
					<ConfirmFromWeekModal
						date={newDate}
						needToConfirmFromWeek={needToConfirmFromWeek}
						buttonHandler={() => {
							dataLayer.push({ event: 'payment_set_date_confirm_week_of_month_go_back_action' });
							setModal(<EditModal />);
						}}
						altButtonHandler={() => {
							dataLayer.push({ event: 'payment_set_date_confirm_week_of_month_confirm_action' });
							if (handleUpdateSelect) handleUpdateSelect(newDate);
							closeModal();
						}}
					/>,
				);
			}

			if (needToConfirmUpfrontDeadlineFrom && newDate > needToConfirmUpfrontDeadlineFrom) {
				return setModal(
					<ConfirmUpfrontDeadlineModal
						date={newDate}
						buttonHandler={() => {
							trackEvent.mutate({
								type: BusinessEventType.LateUpfrontDeadlineConfirmed,
								paymentTransactionId,
								effectiveDate: jsDateToIsoDate(newDate),
								userId: user?.userId ?? '',
								actorId: user?.userId ?? '',
								userRentId: home?.editableRent?.id ?? '',
							});
							if (handleUpdateSelect) handleUpdateSelect(newDate);
							closeModal();
						}}
						altButtonHandler={() => {
							setModal(<EditModal />);
						}}
					/>,
				);
			}

			if (handleUpdateSelect) handleUpdateSelect(newDate);
		}

		closeModal();
	};

	const EditModal = () => {
		return (
			<S.DatePickerWrapper data-testid={testIdCalendar + '-wrapper'}>
				<DayPicker
					selectedDay={date}
					onSelect={handleSelect}
					changeSince={canChangeDateSince}
					changeUntil={canChangeDateUntil}
					testId={testIdCalendar}
				/>
				{infoText && (
					<S.InfoText type="tiny" color="muted">
						{infoText}
					</S.InfoText>
				)}
			</S.DatePickerWrapper>
		);
	};

	return (
		<S.Wrapper onClick={toggleEdit} $isEditable={isEditable}>
			<S.MainContent>
				<S.Left>
					<S.Date>
						<S.Day data-testid={`${testIdCalendar}-day`}>
							<FormattedDate value={date} day="numeric" />
						</S.Day>
						<p>
							<FormattedDate value={date} month="short" />
						</p>
					</S.Date>
					<div>
						<S.Title>{label}</S.Title>
						<S.Amount>{amount}</S.Amount>
					</div>
				</S.Left>
				{isEditable && (
					<S.IconWrapper data-testid={testIdEdit}>
						<Icon icon="Edit" />
					</S.IconWrapper>
				)}
			</S.MainContent>
		</S.Wrapper>
	);
};
