import React from 'react';
import { useIntl } from 'react-intl';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Modal } from '../../../base-ui/components';

import { useInterface } from '../../contexts/hooks';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

export const DirectDebitCardCancelModal = () => {
	const trackEvent = useTrackOnboardingEvent();
	const { closeModal } = useInterface();
	const intl = useIntl();

	return (
		<Modal
			type="warning"
			headerText={intl.formatMessage({
				defaultMessage: 'Are you sure that you don’t need to make debit card payments?',
				id: 'debit-card-cancel-modal.are-you-sure',
			})}
			contentText={intl.formatMessage({
				defaultMessage:
					'Debit cards offer instant and convenient transactions, which can avoid late fees and NSF fees charged by banks.',
				id: 'debit-card-cancel-modal.content',
			})}
			buttonText={intl.formatMessage({
				defaultMessage: 'Set up debit card payments',
				id: 'debit-card-cancel-modal.ok',
			})}
			buttonHandler={closeModal}
			buttonTestId="custom-rent-setup-modal-cancel-button"
			altButtonTestId="custom-rent-setup-modal-decline-button"
			altButtonText={intl.formatMessage({
				defaultMessage: 'No, don’t set up debit card payments',
				id: 'debit-card-cancel-modal.cancel',
			})}
			altButtonHandler={() => {
				trackEvent.mutate(
					{ type: BusinessEventType.OnboardingCustomRentDirectDebitDeclined },
					{
						onSuccess() {
							closeModal();
						},
					},
				);
			}}
		/>
	);
};
