import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Loader, Text } from '../../../base-ui/components';

import { AddDebitCardWrapper } from './add-debit-card-wrapper';

const CardFormLoader = styled.div`
	position: absolute;
	z-index: 0;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export function AddDebitCardLoading(props: { backLink: string }) {
	return (
		<AddDebitCardWrapper backLink={props.backLink}>
			<Text center>
				<FormattedMessage
					defaultMessage="Initializing debit card form..."
					id="add-debit-card-loading.initializing-debit-card-form"
				/>
			</Text>
			<CardFormLoader>
				<Loader />
			</CardFormLoader>
		</AddDebitCardWrapper>
	);
}
