import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { trpc } from '../../../shared/trpc/client';

import { useBankOnboarding } from '../../contexts/hooks';
import { ConnectCard } from '../../templates/connect-card';
import { UnifiedPaymentsPaymentMethod, unifiedPaymentsPaymentMethodSchema } from './2-2-0-select-payment-method';

export const OnboardingConnectCard = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { links } = useBankOnboarding();

	const { paymentMethod } = unifiedPaymentsPaymentMethodSchema.parse(location.state);

	// preload
	trpc.user.paymentCards.defaultCardholderDetails.useQuery();

	const handleOnClick = () => {
		if (paymentMethod === UnifiedPaymentsPaymentMethod.CreditCard) {
			navigate(links.ADD_CREDIT_CARD);
		} else {
			navigate(links.ADD_DEBIT_CARD);
		}
	};

	return <ConnectCard onContinue={handleOnClick} />;
};
