import React from 'react';
import * as Sentry from '@sentry/react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { trpc } from '../../../shared/trpc/client';

import { useFirebase, useInterface } from '../../contexts/hooks';
import { CardAddedModal } from './card-added-modal';
import { UnexpectedProblemModal } from './unexpected-problem-modal';
import { UnsupportedCardModal } from './unsupported-card-modal';

const stateSchema = z
	.object({
		backLink: z.string(),
		abortText: z.string(),
		successLink: z.string(),
	})
	.nullable()
	.optional();

export const useDebitCard = (props: { successLink: To; backLink: string; abortText: string }) => {
	const location = useLocation();
	const { setModal, closeModal, setLoading, isLoading } = useInterface();
	const cardDetails = trpc.user.paymentCards.defaultDebitCard.useQuery();
	const navigate = useNavigate();
	const { authUser } = useFirebase();

	// Page used in 3 different places:
	// 1. Unified payments onboarding
	// 2. Unified payments "change payment method" flow
	// 3. Account settings - add/update card
	const state = stateSchema.parse(location.state);
	const successLink = state?.successLink ?? props.successLink;
	const backLink = state?.backLink ?? props.backLink;
	const abortText = state?.abortText ?? props.abortText;

	const showUnexpectedErrorModal = () => {
		setLoading(false);
		setModal(
			<UnexpectedProblemModal
				buttonHandler={() => {
					closeModal();
				}}
				altButtonText={abortText}
				altButtonHandler={() => {
					closeModal();
					navigate(backLink);
				}}
			/>,
		);
	};

	const createCard = trpc.user.paymentCards.createZumRailsDebitCard.useMutation({
		onSuccess: async (result) => {
			setLoading(false);
			if (result.success) {
				await cardDetails.refetch();
				setModal(
					<CardAddedModal
						result={result}
						buttonHandler={() => {
							closeModal();
							navigate(successLink);
						}}
					/>,
				);
			} else {
				setModal(
					<UnsupportedCardModal
						buttonHandler={() => {
							closeModal();
						}}
						altButtonText={abortText}
						altButtonHandler={() => {
							closeModal();
							navigate(backLink);
						}}
					/>,
				);
			}
		},
		onError: () => showUnexpectedErrorModal(),
	});
	const createZumrailsSdkToken = trpc.user.paymentCards.createZumrailsSdkToken.useMutation({
		onError: () => showUnexpectedErrorModal(),
	});

	const handleSubmit = async (payload) => {
		setLoading(true);
		const started = new Date().getTime();
		let zumrails;
		while (!(zumrails = window['ZumRailsSDK'])) {
			if (new Date().getTime() - started > 30e3) {
				console.error('Failed to initialize ZumRails SDK');
				showUnexpectedErrorModal();
				return;
			}
			await new Promise((resolve) => setTimeout(resolve, 100));
		}

		const token = await createZumrailsSdkToken.mutateAsync({ cardHolderDetails: payload });
		zumrails.init({
			token,
			onLoad: () => {
				const iframe = document.getElementById('ConnectSDKWrapperIframe');
				if (iframe) {
					iframe.style.height = '520px';
				}
			},
			onError: (error) => {
				showUnexpectedErrorModal();
				console.error('ZumRailsSDK onError', error);
				Sentry.captureException(error, (scope) => {
					scope.setContext('user', { id: authUser?.uid });
					scope.setTag('zumrails', 'onError');
					scope.setExtra('error', error);
					return scope;
				});
			},
			onSuccess: ({ userId: zumRailsUserId }: { userId: string }) => {
				createCard.mutate({ zumRailsUserId });
			},
			// this does not work, but it is documented
			onClosed: () => {
				setLoading(false);
			},
			// this works, but it is not documented
			onButtonClose: () => {
				setLoading(false);
			},
		});
	};

	return {
		successLink,
		backLink,
		abortText,
		handleSubmit,
		isLoading,
	};
};
