import React, { ReactNode, useState } from 'react';
import { addBusinessDays } from 'date-fns';
import format from 'date-fns/format';
import { find } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';

import { jsDateToIsoDate } from '../../../../../functions/src/shared/iso-date';
import { amountToCents, centsToAmount } from '../../../../../functions/src/shared/monetary';
import { FeatureType } from '../../../../../functions/src/shared/user';
import { notNullish } from '../../../../../functions/src/shared/utils';

import { trpc } from '../../../shared/trpc/client';

import { Button, ButtonProps } from '../../../base-ui/components/Button/Button';

import { InstaMoneyPaymentScheduledModalContent } from '../../components/modals/insta-money-payment-scheduled';
import { InstaMoneySentModalContent } from '../../components/modals/insta-money-sent';
import {
	formatEligibilityResults,
	instaMoneyCannotRequestReasonsMap,
	ProfileErrorsModal,
} from '../../components/profile/profile-errors/profile-errors-modal';
import { links } from '../../Router/paths';
import { useInterface, useUserData } from '../hooks';

interface InstaMoneyState {
	amount: number;
	use: string | undefined;
	setUse: (use: string) => void;
	noTipReason: string | undefined;
	setNoTipReason: (reason: string) => void;
	tipAmount: string;
	setTipAmount: (use: string) => void;
	paybackDate: Date;
	setPaybackDate: (date: Date) => void;
	paybackDateSince: Date;
	paybackDateUntil: Date;
	submit: () => Promise<unknown>;
	submitting: boolean;
}

const amount = 100;
const defaultPaybackDate = addBusinessDays(new Date(), 5);
const paybackDateSince = addBusinessDays(new Date(), 1);
const paybackDateUntil = addBusinessDays(new Date(), 10);

const initialState: InstaMoneyState = {
	amount,
	use: undefined,
	setUse: () => {
		throw new Error('instamoney context missing');
	},
	noTipReason: undefined,
	setNoTipReason: () => {
		throw new Error('instamoney context missing');
	},
	tipAmount: '4',
	setTipAmount: () => {
		throw new Error('instamoney context missing');
	},
	paybackDate: defaultPaybackDate,
	setPaybackDate: () => {
		throw new Error('instamoney context missing');
	},
	paybackDateSince,
	paybackDateUntil,
	submit: async () => {
		throw new Error('instamoney context missing');
	},
	submitting: false,
};

const InstaMoneyContext = React.createContext(initialState);

export const InstaMoneyProvider = ({ children }: { children: ReactNode }) => {
	const navigate = useNavigate();

	const { setModal } = useInterface();

	const {
		handlers: { forceRefetch },
	} = useUserData();
	const { canRequest } = useInstaMoneyState();

	const [paybackDate, setPaybackDate] = useState(defaultPaybackDate);
	const [use, setUse] = useState<string>();
	const [tipAmount, setTipAmount] = useState<string>('4');
	const [noTipReason, setNoTipReason] = useState<string>();
	const [submitting, setSubmitting] = useState(false);

	const { mutateAsync } = trpc.user.cashAdvance.request.useMutation();

	// disable access when cannotRequestInstaMoney
	if (!canRequest) {
		return <Navigate to={links.ACCOUNT.DASHBOARD} replace />;
	}

	const submit = async () => {
		if (!(use && tipAmount && paybackDate)) throw new Error('submitting without filled data');

		setSubmitting(true);

		await mutateAsync({
			feeAmountCents: amountToCents(Math.abs(parseInt(tipAmount))),
			reason: use,
			noTipReason,
			// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
			paybackDate: format(paybackDate, 'yyyy-MM-dd'),
		});

		setSubmitting(false);

		await forceRefetch();

		setModal(<InstaMoneySentModalContent paybackDate={jsDateToIsoDate(paybackDate)} />);
		navigate(links.ACCOUNT.DASHBOARD);
	};

	return (
		<InstaMoneyContext.Provider
			value={{
				amount,
				paybackDate,
				setPaybackDate,
				paybackDateSince,
				paybackDateUntil,
				use,
				setUse,
				noTipReason,
				setNoTipReason,
				tipAmount,
				setTipAmount,
				submit,
				submitting,
			}}
		>
			{children}
		</InstaMoneyContext.Provider>
	);
};

export const useInstaMoney = () => {
	const context = React.useContext(InstaMoneyContext);
	if (context === undefined) {
		throw new Error('useUser must be used within a UIProvider');
	}
	return context;
};

export const useInstaMoneyState = () => {
	const { setModal, closeModal } = useInterface();
	const { home } = useUserData();

	const { data } = trpc.user.cashAdvance.info.useQuery();

	const canRequest = data?.canRequest;
	const cannotRequestReasons = data?.cannotRequestReasons;
	const activeCashAdvanceId = data?.activeCashAdvanceId;
	const nextPaybackDate = data?.nextPaybackDate;
	const intl = useIntl();

	// allow request
	const cta: ButtonProps = {
		children: <FormattedMessage defaultMessage="Request" id="insta-money.request" />,
		linkTo: links.ACCOUNT.INSTA_MONEY.REQUEST,
	};

	if (activeCashAdvanceId && nextPaybackDate) {
		// pending => cannot request
		cta.children = <FormattedMessage defaultMessage="Requested" id="insta-money.requested" />;
		cta.linkTo = undefined;

		const outstandingCashAdvancePayment = find(home?.payments, {
			featureId: FeatureType.InstaMoney,
			originatingId: activeCashAdvanceId,
		});

		if (outstandingCashAdvancePayment) {
			cta.onClick = () =>
				setModal(
					<InstaMoneyPaymentScheduledModalContent
						amount={centsToAmount(outstandingCashAdvancePayment.amountCents)}
						effectiveDate={outstandingCashAdvancePayment.effectiveDate}
					/>,
				);
		} else {
			cta.onClick = () => setModal(<InstaMoneySentModalContent paybackDate={nextPaybackDate} />);
		}
	} else if (!data?.canRequest) {
		// not eligible => info page
		cta.onClick = () =>
			setModal(
				<ProfileErrorsModal
					title={intl.formatMessage({
						defaultMessage: 'You are currently not eligible for InstaMoney',
						id: 'insta-money.not-eligible',
					})}
					errors={[
						...formatEligibilityResults(
							home?.eligibilityRules?.ruleResponses,
							Boolean(home?.accountToVerify),
						),
						...(cannotRequestReasons || [])
							.map((reason) => instaMoneyCannotRequestReasonsMap[reason])
							.filter(notNullish),
					]}
					cta={
						<Button onClick={() => closeModal()}>
							<FormattedMessage
								defaultMessage="Return Home"
								id="disable-custom-rent.return-home-button"
							/>
						</Button>
					}
				/>,
			);
		cta.linkTo = undefined;
	}

	return {
		canRequest,
		cannotRequestReasons,
		activeCashAdvanceId,
		cta,
	};
};
