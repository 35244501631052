import React, { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { CircularProgress } from '@mui/material';

import { publicConfig } from '../../../../../functions/src/shared/config';
import { jsonParseSafe } from '../../../../../functions/src/shared/json-parse';

import { getEnvironment } from '../../../shared/environment';
import { trpc } from '../../../shared/trpc/client';

import { Button, Footer, Spacing } from '../../../base-ui/components';

import { Moneris, ReceiptEvent } from '../../types/moderis.type';
import { MonerisTestData } from './moneris-test-data';

type Props = {
	onSuccess: () => void;
	onCancel?: () => void;
};

export const MonerisCheckout = ({ onSuccess, onCancel }: Props) => {
	const initScriptRef = useRef<boolean>(false);
	const receiptCompleted = useRef<boolean>(false);
	const monerisCheckout = useRef<Moneris | null>(null);

	const preauthorizeCard = trpc.user.moneris.preauthorizeCard.useMutation({
		onSuccess: (data) => {
			monerisCheckout.current?.startCheckout(data.ticketId);
		},
	});
	const receipt = trpc.user.moneris.processReceipt.useMutation();

	useEffect(() => {
		if (initScriptRef.current) {
			return;
		}
		const script = document.createElement('script');

		script.src = publicConfig.moneris.scriptUrl;

		document.body.appendChild(script);
		initScriptRef.current = true;
		receipt.reset();

		function genericHandler(...args: string[]) {
			// eslint-disable-next-line no-console
			console.log('genericHandler', args);
		}

		const cancelHandler = debounce(() => {
			// eslint-disable-next-line no-console
			console.log('cancelHandler');
			onCancel?.();
		});

		const completeHandler = debounce(
			(arg: string) => {
				// eslint-disable-next-line no-console
				console.log('completeHandler');
				const event = jsonParseSafe(arg) as ReceiptEvent;
				if (receipt.isLoading || receipt.data || receipt.error) {
					return;
				}
				receipt.mutate(
					{ ticketId: event.ticket },
					{
						onSuccess() {
							onSuccess();
						},
					},
				);
			},
			100,
			{
				leading: true,
				maxWait: 100,
				trailing: false,
			},
		);

		function iframeLoaded() {
			// eslint-disable-next-line no-console
			console.log('Iframe loaded');
		}

		function loadListener() {
			const myCheckout: Moneris = new window.monerisCheckout();
			monerisCheckout.current = myCheckout;
			myCheckout.setMode(getEnvironment() !== 'production' ? 'qa' : 'prod');
			myCheckout.setCheckoutDiv('monerisCheckout');
			myCheckout.setCallback('page_loaded', iframeLoaded);
			myCheckout.setCallback('cancel_transaction', cancelHandler);
			myCheckout.setCallback('error_event', genericHandler);
			myCheckout.setCallback('payment_receipt', genericHandler);
			myCheckout.setCallback('payment_complete', completeHandler);
			preauthorizeCard.mutate({});
		}
		script.addEventListener('load', loadListener);

		return () => {
			script.removeEventListener('load', loadListener);
			document.body.removeChild(script);
			initScriptRef.current = false;
		};
	}, []);

	function handleTryAgain() {
		receipt.reset();
		receiptCompleted.current = false;
		preauthorizeCard.mutate({});
	}

	if (receipt.isLoading) {
		return <CircularProgress sx={{ mx: 'auto', display: 'block' }} />;
	}
	if (receipt.data) {
		return (
			<>
				<h3>
					<FormattedMessage id="moneris.card-saved" defaultMessage="Card saved" />
				</h3>
				<p>
					<FormattedMessage
						id="moneris.card-saved"
						defaultMessage="Your card was saved for CustomRent. You can now continue with your application."
					/>
				</p>
				<Spacing $size="l" />
				<Footer>
					<Button type="large" testId="moneris-checkout-continue">
						<FormattedMessage id="common.continue" defaultMessage="Continue" />
					</Button>
				</Footer>
			</>
		);
	}
	if (receipt.error) {
		return (
			<>
				<h3>
					<FormattedMessage id="moneris.error" defaultMessage="Error" />
				</h3>
				<p>
					<FormattedMessage
						id="moneris.error-message"
						defaultMessage="Something went wrong, please try again. If the problem persists, please contact support."
					/>
				</p>
				<Spacing $size="l" />
				<Footer>
					<Button type="large" onClick={() => handleTryAgain()} testId="moneris-try-again">
						<FormattedMessage id="moneris.try-again" defaultMessage="Try again" />
					</Button>
				</Footer>
			</>
		);
	}

	return (
		<>
			<div id="outerDiv" style={{ height: '1300px' }}>
				<CircularProgress sx={{ mx: 'auto', display: 'block' }} />
				<div id="monerisCheckout"></div>
			</div>
			<MonerisTestData />
		</>
	);
};
