import React, { ComponentProps } from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export const VisaIcon = (props: ComponentProps<typeof Svg>) => (
	<Svg height="1em" viewBox="0 0 49 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect x="3" y="3" width="42.1755" height="27.29" rx="12" fill="#2666AC" />
		<rect
			x="1.5"
			y="1.5"
			width="45.1755"
			height="30.29"
			rx="13.5"
			stroke="black"
			strokeOpacity="0.1"
			strokeWidth="3"
		/>
		<path
			d="M8.03119 11.2577H9.87689L11.6747 18.5175L15.5644 11.2577H17.5947L12.2148 21.3134H10.5263L8.03119 11.2577ZM18.8661 11.2577H20.7119L19.3242 21.3134H17.5126L18.8661 11.2577ZM27.9921 12.6591L26.6181 13.8759C26.053 13.1467 25.5266 12.7822 25.039 12.7822C24.7975 12.7822 24.5969 12.8551 24.4374 13.0009C24.2779 13.1422 24.1982 13.3131 24.1982 13.5136C24.1982 13.7004 24.2711 13.9078 24.4169 14.1357C24.5628 14.3635 25.0048 14.8671 25.7431 15.6464C26.135 16.0611 26.3925 16.3551 26.5156 16.5282C26.8118 16.9293 27.0237 17.3053 27.1513 17.6562C27.2835 18.0025 27.3495 18.3557 27.3495 18.7157C27.3495 19.5224 27.0624 20.1991 26.4882 20.746C25.914 21.2929 25.178 21.5663 24.2802 21.5663C22.913 21.5663 21.7988 20.9169 20.9374 19.6181L22.3867 18.4491C22.97 19.3378 23.6012 19.7822 24.2802 19.7822C24.6038 19.7822 24.8749 19.6773 25.0937 19.4677C25.317 19.2581 25.4286 19.0051 25.4286 18.7089C25.4286 18.4765 25.3603 18.2395 25.2236 17.998C25.0914 17.7564 24.7086 17.3007 24.0751 16.6308C23.4052 15.9244 22.9654 15.3912 22.7558 15.0312C22.4732 14.5435 22.332 14.065 22.332 13.5956C22.332 12.9758 22.5348 12.4198 22.9404 11.9277C23.4508 11.3124 24.1389 11.0048 25.0048 11.0048C26.1943 11.0048 27.19 11.5562 27.9921 12.6591ZM33.5908 11.2577H35.1835L37.6855 21.3134H35.8535L35.3203 19.1738H31.3486L30.1933 21.3134H28.163L33.5908 11.2577ZM34.8622 17.3964L34.0556 14.0947L32.2851 17.3964H34.8622Z"
			fill="white"
		/>
		<path d="M5.4809 11.6832H10.236L10.8562 14.1641L5.4809 11.6832Z" fill="#EEA84D" />
	</Svg>
);
