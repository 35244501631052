import React from 'react';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { MonerisCheckout } from '../../components/moneris/moneris-checkout';
import { useInterface } from '../../contexts/hooks';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { DirectDebitCardCancelModal } from './direct-debit-card-cancel-modal';

export const DirectDebitCardDetails = () => {
	const trackEvent = useTrackOnboardingEvent();
	const { setModal } = useInterface();

	function handleSuccess() {
		trackEvent.mutate({ type: BusinessEventType.OnboardingCustomRentDirectDebitSuccess });
	}

	function handleCancel() {
		setModal(<DirectDebitCardCancelModal />);
	}

	return <MonerisCheckout onSuccess={handleSuccess} onCancel={handleCancel} />;
};
