import React from 'react';
import { subYears } from 'date-fns';
import styled from 'styled-components';

import { FormInput } from '../../base-ui/components';
import { DayPicker } from '../../base-ui/components/DayPicker/DayPicker';

import { useInterface } from '../contexts/hooks';

export type FormattedDateInputProps = {
	name: string;
	handleSelect: (d: Date | undefined) => void;
	placeholder: string;
	testId?: string;
	selectedDay?: Date;
	changeSince?: Date;
	changeUntil?: Date;
	parseDate?: (date: string) => Date;
};

const Wrapper = styled.div`
	input[type='text'] {
		caret-color: rgba(255, 255, 255, 0);
	}
`;

export const FormattedDateInput = (props: FormattedDateInputProps) => {
	const { setModal, closeModal } = useInterface();

	return (
		<Wrapper>
			<FormInput
				type="text"
				name={props.name}
				placeholder={props.placeholder}
				inputMode="none"
				autoComplete="off"
				testId={props.testId}
				onClick={(formattedDate) => {
					let d = props.selectedDay ?? new Date();
					if (formattedDate) {
						d = props.parseDate?.(formattedDate as string) ?? new Date(formattedDate as string);
					}
					return setModal(
						<DayPicker
							onSelect={(selectedDate) => {
								props.handleSelect(selectedDate);
								closeModal();
							}}
							selectedDay={d ?? props.selectedDay ?? new Date()}
							changeSince={props.changeSince ?? subYears(new Date(), 120)}
							changeUntil={props.changeUntil}
							captionLayout="dropdown"
						/>,
					);
				}}
			/>
		</Wrapper>
	);
};
