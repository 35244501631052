import React, { ReactNode } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';

import {
	Radio as BaseRadio,
	RadioGroup as BaseRadioGroup,
	Button,
	Footer,
	Spacing,
	Text,
} from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { MasterCardIcon } from '../../components/icons/mastercard';
import { MasterCardDebitIcon } from '../../components/icons/mastercard-debit';
import { VisaIcon } from '../../components/icons/visa';
import { VisaDebitIcon as BaseVisaDebitIcon } from '../../components/icons/visa-debit';
import { useBankOnboarding } from '../../contexts/hooks';
import { links } from '../../Router/paths';
import { useConnectedLandlord } from '../../utils/use-connected-landlord';

const RadioGroup = styled(BaseRadioGroup)`
	grid-template-columns: 1fr;
	row-gap: 0.5rem;
`;

const RadioLabelIcons = styled.div`
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	margin-left: 1.5rem;
	gap: 0.5rem;

	font-size: 3.5rem;
	@media (max-width: 450px) {
		font-size: 3rem;
	}
`;

const Radio = styled(BaseRadio)`
	align-items: baseline;
`;

const VisaDebitIcon = styled(BaseVisaDebitIcon)`
	font-size: 2.9rem;
	@media (max-width: 450px) {
		font-size: 2rem;
	}
`;

export enum UnifiedPaymentsPaymentMethod {
	DebitCard = 'debit-card',
	CreditCard = 'credit-card',
}

export const unifiedPaymentsPaymentMethodSchema = z.object({
	paymentMethod: z.nativeEnum(UnifiedPaymentsPaymentMethod),
});

export const cardFlow = ['onboarding', 'manage', 'add'] as const;
export type CardFlow = (typeof cardFlow)[number];

export function cardFlowProps(flow: CardFlow) {
	let successLink: string;
	let abortText: ReactNode;
	let backLink: string;
	switch (flow) {
		case 'onboarding':
			backLink = links.REGISTRATION.BANK.SELECT_PAYMENT_METHOD;
			abortText = (
				<FormattedMessage defaultMessage="Select different payment method" id="add-debit-card-abort-text" />
			);
			successLink = links.REGISTRATION.CONFIGURE.CONFIRM_CUSTOM_RENT;
			break;

		case 'manage':
			backLink = links.ACCOUNT.BANK.SELECT_PAYMENT_METHOD;
			abortText = (
				<FormattedMessage defaultMessage="Select different payment method" id="add-debit-card-abort-text" />
			);
			successLink = links.ACCOUNT.DASHBOARD;
			break;

		case 'add':
			backLink = links.REGISTRATION.BANK.SELECT_PAYMENT_METHOD;
			abortText = (
				<FormattedMessage defaultMessage="Select different payment method" id="add-debit-card-abort-text" />
			);
			successLink = links.REGISTRATION.CONFIGURE.CONFIRM_CUSTOM_RENT;
			break;

		default:
			const _never: never = flow;
			throw new Error(`unexpected flow ${_never}`);
	}

	return { successLink, abortText, backLink };
}

type FormData = z.infer<typeof unifiedPaymentsPaymentMethodSchema>;
export const SelectPaymentMethod = () => {
	const navigate = useNavigate();
	const { links: bankOnboardingLinks } = useBankOnboarding();
	const { landlordConnectedImage } = useConnectedLandlord();

	const form = useForm<FormData>({
		resolver: zodResolver(unifiedPaymentsPaymentMethodSchema),
	});

	const submit = async ({ paymentMethod }: FormData) => {
		navigate(bankOnboardingLinks.CONNECT_CARD, { state: { paymentMethod } });
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<BackButton />

				<Text type="title">
					<FormattedMessage
						defaultMessage="Select your payment method"
						id="select-payment-method.select-your-payment-method"
					/>
				</Text>
				<Spacing $size="s" />
				<Text>
					<FormattedMessage
						defaultMessage="Please note that you will need to <b>provide your Visa or Mastercard details</b>. Interac Debit is currently not supported."
						id="select-payment-method.please-note"
					/>
				</Text>
				<Spacing $size="l" />

				<RadioGroup name="fullRent">
					<Radio
						{...form.register('paymentMethod')}
						value={UnifiedPaymentsPaymentMethod.DebitCard}
						label={
							<>
								<FormattedMessage defaultMessage="Debit Card" id="account-settings.debit-card.label" />
								<RadioLabelIcons>
									<VisaDebitIcon />
									<MasterCardDebitIcon />
								</RadioLabelIcons>
							</>
						}
						secondaryLabel={
							<FormattedMessage
								defaultMessage="Scheduled payments directly from your bank account with no processing or NSF fees."
								id="account-settings.debit-card.label-secondary-label"
							/>
						}
						paper
					/>
					<Spacing $size="s" />

					<Radio
						{...form.register('paymentMethod')}
						value={UnifiedPaymentsPaymentMethod.CreditCard}
						label={
							<>
								<FormattedMessage defaultMessage="Credit Card" id="select-payment-method.credit-card" />
								<RadioLabelIcons>
									<VisaIcon />
									<MasterCardIcon />
								</RadioLabelIcons>
							</>
						}
						secondaryLabel={
							<FormattedMessage
								defaultMessage="2.9% processing fees apply."
								id="select-payment-method.credit-card-secondary-label"
							/>
						}
						paper
					/>
					<Spacing $size="s" />
				</RadioGroup>

				{landlordConnectedImage}

				<Footer stickyOnMobile>
					<Button htmlType="submit">
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
