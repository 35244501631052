import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import imageStatements from 'url:../static/shapes/upload-statements.png';
import { z } from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';

import { Upload } from '../../shared/components/upload';
import { trpc } from '../../shared/trpc/client';
import { uploadLeaseAgreementsOptions } from '../../shared/upload-lease-agreements';

import { Button, Spacing, Text } from '../../base-ui/components';
import { colors } from '../../base-ui/variables';

import { useUserData } from '../contexts/hooks';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

type Props = {
	onNext: () => void;
};

const Img = styled.img`
	@media (max-width: 450px) {
		left: 0;
	}
	position: relative;
	top: 0;
	left: 10%;
`;

const locationStateSchema = z.object({ isLeaseRenewal: z.boolean() });

export const UploadLeaseAgreement = ({ onNext }: Props) => {
	const { user } = useUserData();
	const trpcUtils = trpc.useUtils();

	const trackOnboardingEvent = useTrackOnboardingEvent();
	const leaseAgreementUploadReminder = trpc.user.remindLeaseAgreementUpload.useMutation();

	useEffect(() => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingLeaseAgreementUploadStart });
	}, []);

	useEffect(() => {
		if (!user?.userId) return;

		leaseAgreementUploadReminder.mutate({ userId: user?.userId });
	}, [user]);

	const buttonHandler = () => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingLeaseAgreementUploadEnd });
		trpcUtils.user.home.invalidate();
		onNext();
	};
	const location = useLocation();

	const state = locationStateSchema.safeParse(location.state);
	const customMetadata: Record<string, string> = {};
	if (state.success) {
		customMetadata['isLeaseRenewal'] = state.data.isLeaseRenewal.toString();
	}
	return (
		<>
			<Img src={imageStatements} width="420px" height="250px" />
			<Upload
				{...uploadLeaseAgreementsOptions(user?.userId ?? '')}
				customMetadata={customMetadata}
				header={
					<div style={{ marginTop: '25px' }}>
						<Text type="title" center>
							<FormattedMessage
								defaultMessage="Upload your lease agreement"
								id="upload-lease-agreement.title"
							/>
						</Text>
						<Spacing $size="s" />
						<Text center>
							<FormattedMessage
								defaultMessage="Your lease details are securely recorded in our system as required by the credit bureaus."
								id="upload-lease-agreement.description"
							/>
						</Text>
						<Spacing $size="l" />
					</div>
				}
				buttonComp={Button}
				backButton={
					<Button type="link" onClick={buttonHandler} testId="onboarding-lease-agreement-upload-later-button">
						<FormattedMessage defaultMessage="I'll upload later" id="upload-lease-agreement.back-button" />
					</Button>
				}
				dragAndDropText={
					<p
						style={{
							padding: '5px 10px 10px 5px',
							textAlign: 'center',
							fontSize: '2rem',
							color: colors.teal,
							fontWeight: '600',
						}}
					>
						<FormattedMessage
							defaultMessage="Browse or drag and drop your files here"
							id="upload-lease-agreement.drag-and-drop-text"
						/>
					</p>
				}
				uploadOnDrop
				onContinue={buttonHandler}
			/>
		</>
	);
};
