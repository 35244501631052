import { publicConfig } from '../../../functions/src/shared/config';

import { leaseAgreementStorage } from '../app/contexts/Firebase/firebaseApi';

export const uploadLeaseAgreementsOptions = (userId: string) => ({
	storage: leaseAgreementStorage,
	pathPrefix: `${userId}/`,
	metadata: {
		[publicConfig.leaseAgreements.uploadMetadata.userId]: userId,
	},
});
