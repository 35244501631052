import React from 'react';
import { FormattedMessage } from 'react-intl';

import { WarningModal } from '../../../base-ui/components';

export function UnsupportedCardModal(props: {
	buttonHandler: () => void;
	altButtonText: string;
	altButtonHandler: () => void;
}) {
	return (
		<WarningModal
			headerText={<FormattedMessage defaultMessage="Unsupported card" id="unsupported-card-modal.header-text" />}
			contentText={
				<FormattedMessage
					defaultMessage="Card is not supported for Visa Direct Debit payments."
					id="unsupported-card-modal.content-text"
				/>
			}
			buttonText={<FormattedMessage defaultMessage="Retry" id="unexpected-problem-modal.retry-button" />}
			buttonHandler={props.buttonHandler}
			altButtonText={props.altButtonText}
			altButtonHandler={props.altButtonHandler}
		/>
	);
}
