import * as SentryLib from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { publicConfig } from '../../../functions/src/shared/config';

import { getEnvironment } from './environment';
import { firebaseErrors } from './firebase-errors';

const dsn = publicConfig.sentry[getEnvironment()].dsn;
if (dsn) {
	SentryLib.init({
		dsn,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
		environment: getEnvironment(),
		ignoreErrors: [
			// recaptcha
			/Non-Error promise rejection captured with value:/,
		],
		denyUrls: [
			// recaptcha
			/recaptcha\/releases/i,
		],
		beforeSend(event, hint) {
			const hintErrorMessage = hint?.originalException
				? typeof hint.originalException === 'string'
					? hint.originalException
					: hint.originalException.message
				: undefined;

			if (hintErrorMessage && firebaseAuthNonErrors.some((m) => hintErrorMessage.includes(m))) {
				event.level = SentryLib.Severity.Info;
			}

			return event;
		},
	});
}

const firebaseAuthNonErrors = [
	firebaseErrors.ERROR_CODE_INVALID_PASSWORD,
	firebaseErrors.ERROR_CODE_INVALID_ACTION_CODE,
	firebaseErrors.ERROR_CODE_CODE_EXPIRED,
	firebaseErrors.ERROR_CODE_EMAIL_ALREADY_IN_USE,
	firebaseErrors.ERROR_CODE_EMAIL_NOT_FOUND,
	firebaseErrors.ERROR_CODE_MULTI_FACTOR_INVALID_CODE,
	firebaseErrors.ERROR_CODE_TOO_MANY_REQUEST,
	firebaseErrors.ERROR_CODE_NETWORK_REQUEST_FAILED,
	firebaseErrors.ERROR_CODE_RECENT_SIGN_IN,
	firebaseErrors.ERROR_CODE_USER_DISABLED,
	firebaseErrors.ERROR_CODE_MULTI_FACTOR_INVALID_CODE,
	firebaseErrors.ERROR_CODE_INVALID_ACTION_CODE,
	firebaseErrors.ERROR_CODE_EMAIL_ALREADY_IN_USE,
	firebaseErrors.ERROR_CODE_CODE_EXPIRED,
	firebaseErrors.ERROR_CODE_CAPTCHA_FAILED,
];
