import React, { ComponentProps } from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export const VisaDebitIcon = (props: ComponentProps<typeof Svg>) => (
	<Svg height="1em" viewBox="0 0 39 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M0 0.388532C0.51364 0.124083 1.10102 0.23088 1.6528 0.220709C2.91656 0.253765 4.18286 0.190196 5.44662 0.246137C6.0162 0.243594 6.50441 0.716549 6.58323 1.26833C6.97991 3.19067 7.34352 5.12063 7.73257 7.04805C7.08924 4.79007 5.39067 2.94656 3.3946 1.77434C2.33935 1.14119 1.16205 0.77249 0 0.388532Z"
			fill="#F4A929"
		/>
		<path
			d="M10.9949 0.22583C12.0908 0.238544 13.1842 0.233458 14.2776 0.22583C12.6146 4.20018 11.0635 8.2203 9.36496 12.1794C8.29699 12.1463 7.22903 12.1616 6.16107 12.1616C5.21515 8.70597 4.33281 5.23255 3.39453 1.77438C5.39061 2.94659 7.08918 4.7901 7.7325 7.04808C7.82912 7.48544 7.89778 7.93297 7.98169 8.37541C8.97846 5.65719 9.98794 2.94151 10.9949 0.22583Z"
			fill="#1B4DA2"
		/>
		<path
			d="M15.5057 0.220703C16.5356 0.228331 17.5654 0.228331 18.5952 0.220703C17.962 4.20268 17.2958 8.17958 16.6779 12.1641C15.6532 12.1666 14.6285 12.1666 13.6012 12.1641C14.1911 8.17703 14.8853 4.20522 15.5057 0.220703Z"
			fill="#1B4DA2"
		/>
		<path
			d="M21.5245 0.970742C23.3706 -0.242159 25.7837 -0.206561 27.8255 0.464731C27.7314 1.34707 27.5839 2.22179 27.4314 3.09396C26.3456 2.57269 25.0793 2.3413 23.8995 2.64389C23.3426 2.77611 22.7934 3.46774 23.2562 3.98647C24.2071 4.96035 25.7048 5.18157 26.5999 6.24191C27.6373 7.25647 27.5992 8.96521 26.8923 10.1527C26.1447 11.4063 24.7055 12.0623 23.3045 12.2505C21.7203 12.4158 20.0701 12.3166 18.5851 11.7038C18.7427 10.8138 18.875 9.91875 19.0352 9.02878C20.3193 9.69245 21.8093 10.0255 23.2409 9.7077C23.7266 9.54497 24.2275 9.14575 24.1843 8.57617C24.0241 7.89725 23.3146 7.60737 22.7629 7.2997C21.6923 6.77335 20.5405 6.12748 20.0599 4.97052C19.4623 3.50843 20.2531 1.79714 21.5245 0.970742Z"
			fill="#1B4DA2"
		/>
		<path
			d="M31.6525 1.44384C31.8584 0.894601 32.2653 0.297049 32.9086 0.263993C33.8545 0.197881 34.8055 0.253822 35.7514 0.236023C36.5981 4.21292 37.4169 8.19235 38.251 12.1692C37.3 12.1718 36.349 12.1718 35.398 12.1743C35.2632 11.5768 35.1361 10.9767 35.014 10.374C33.6943 10.374 32.3772 10.3766 31.06 10.369C30.8489 10.9716 30.6303 11.5742 30.404 12.1718C29.3258 12.1718 28.2477 12.1718 27.1696 12.1743C28.6596 8.59665 30.1649 5.02406 31.6525 1.44384Z"
			fill="#1B4DA2"
		/>
		<path
			d="M31.9575 7.91239C32.5322 6.43504 33.0026 4.91447 33.6459 3.46509C33.8366 4.96532 34.2104 6.43504 34.5181 7.91494C33.6637 7.91494 32.8119 7.91494 31.9575 7.91239Z"
			fill="white"
		/>
		<path
			d="M19.76 20.1814C20.9093 20.2017 22.3892 20.0084 23.1215 21.1196C23.8945 22.5054 23.3224 24.5219 21.8857 25.2415C20.9652 25.7322 19.882 25.5212 18.8853 25.5669C19.1904 23.7743 19.4269 21.9689 19.76 20.1814Z"
			fill="#161314"
		/>
		<path
			d="M24.881 20.1916C25.7888 20.1916 26.6965 20.189 27.6043 20.1967C27.5662 20.3568 27.4924 20.6747 27.4543 20.8349C26.7932 20.8679 26.132 20.8451 25.4709 20.8501C25.4074 21.3867 25.3234 21.9207 25.2116 22.447C25.7277 22.4496 26.2439 22.4495 26.7627 22.4521C26.7627 22.6225 26.7576 22.9632 26.7576 23.1336C26.216 23.131 25.6769 23.1285 25.1353 23.1234C25.0234 23.7362 24.9191 24.349 24.82 24.9643C25.5218 24.9745 26.2236 24.972 26.9279 24.9745C26.8949 25.1118 26.8313 25.3839 26.7983 25.5212C25.8803 25.6229 24.9522 25.5441 24.0292 25.567C24.2987 23.7718 24.5759 21.9791 24.881 20.1916Z"
			fill="#181415"
		/>
		<path
			d="M27.6755 25.5796C27.973 23.7844 28.2477 21.9841 28.5325 20.1864C29.3055 20.2195 30.1547 20.0644 30.8515 20.4865C31.6601 21.1247 31.2634 22.419 30.381 22.78C31.3702 23.1843 31.3498 24.6515 30.5158 25.1982C29.6894 25.7729 28.6164 25.5033 27.6755 25.5796Z"
			fill="#191617"
		/>
		<path
			d="M32.6898 20.222C32.8728 20.2093 33.239 20.1839 33.4246 20.1711C33.1347 21.9765 32.8728 23.7844 32.5575 25.5847C32.3795 25.5796 32.021 25.5694 31.843 25.5644C32.1685 23.792 32.4177 22.0045 32.6898 20.222Z"
			fill="#181415"
		/>
		<path
			d="M34.3604 20.2092C35.6394 20.1635 36.9184 20.2067 38.2 20.1914C38.2 20.3567 38.2 20.6873 38.2025 20.8525C37.6584 20.85 37.1142 20.8475 36.5701 20.8424C36.2649 22.4062 36.0946 23.9929 35.792 25.5541C35.6165 25.5668 35.2682 25.5897 35.0953 25.5999C35.3419 24.0158 35.5733 22.4291 35.8479 20.85C35.3038 20.85 34.7596 20.8475 34.2155 20.8449C34.2511 20.6847 34.3248 20.3669 34.3604 20.2092Z"
			fill="#171314"
		/>
		<path
			d="M20.36 20.8295C21.161 20.8194 22.1781 20.7914 22.5773 21.6483C22.8901 22.6654 22.6867 23.9546 21.7509 24.5852C21.1559 25.0353 20.3778 24.9717 19.676 24.9844C19.9303 23.6037 20.0981 22.2103 20.36 20.8295Z"
			fill="white"
		/>
		<path
			d="M29.102 20.8223C29.6665 20.7995 30.5743 20.7232 30.6023 21.5191C30.5921 22.4116 29.5673 22.5209 28.8884 22.5107C28.9571 21.9462 29.0334 21.3868 29.102 20.8223Z"
			fill="white"
		/>
		<path
			d="M28.7791 23.0803C29.3004 23.1363 30.0607 22.9735 30.3327 23.5558C30.6201 24.0135 30.3607 24.6543 29.8827 24.8679C29.4301 25.0433 28.9317 25.0001 28.4562 25.0332C28.5731 24.3822 28.6901 23.7338 28.7791 23.0803Z"
			fill="white"
		/>
	</Svg>
);
