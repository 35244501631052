/**
 * Format the week number header.
 *
 * @defaultValue `""`
 * @group Formatters
 * @see https://daypicker.dev/docs/translation#custom-formatters
 */
export function formatWeekNumberHeader() {
  return ``;
}
