import { differenceInDays } from 'date-fns';
import { first } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import { CreditSubjectRentReportingStatus } from '../../../../../../functions/src/shared/credit-reporting';
import { isoDateToJsDate } from '../../../../../../functions/src/shared/iso-date';
import { TransactionStatus } from '../../../../../../functions/src/shared/payment-transaction';
import {
	ManualResidencyLeaseAgreementStatus,
	UserPmsResidencyType,
	verifiedLeaseAgreementStatuses,
} from '../../../../../../functions/src/shared/pms';
import { ensureExhaustiveCheck, typedIncludes } from '../../../../../../functions/src/shared/utils';

import { RouterOutput, trpc } from '../../../../shared/trpc/client';

import { links } from '../../../Router/paths';

export type CreditBuilderErrorState =
	| 'pending-setup'
	| 'pending-payment'
	| 'pending-confirmation'
	| 'upload-lease-agreement-pending'
	| 'indirect-lease-dates-missing'
	| 'indirect-rent-amount-missing'
	| 'indirect-last-month-of-lease'
	| 'lease-agreement-update-requested';

type Home = RouterOutput['user']['home'];
type CreditSubject = RouterOutput['creditReporting']['getUserInfo'];

const getPaymentFromGetInfo = (data?: CreditSubject) => {
	const payment = first(data?.payments);

	const unpaid = data?.paidByResident && data?.unpaid;

	return { payment, unpaid };
};

export const creditBuilderErrorService = {
	resolveErrorState(input: {
		home: Home | null;
		creditSubject?: CreditSubject | null;
		setupIncomplete: boolean;
	}): CreditBuilderErrorState | null {
		const { home, creditSubject, setupIncomplete } = input;

		if (setupIncomplete) {
			return 'pending-setup';
		}

		const indirectModel = Boolean(home?.dashboard.indirectModel);
		const { unpaid } = getPaymentFromGetInfo(creditSubject);
		const pendingCreditBuilderConfirmation =
			creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.NotConfirmed;

		const leaseAgreementUploadPending = creditSubject?.leaseAgreementUploadPending;
		const leaseAgreementStatus = home?.residency?.manualResidencyLeaseAgreementStatus;

		const residency = home?.residency;
		if (indirectModel && residency && residency.type === UserPmsResidencyType.Indirect) {
			if (!residency.manualResidencyRentAmountCents) {
				return 'indirect-rent-amount-missing';
			}
			if (!residency.residentStartDate || !residency.residentEndDate) {
				return 'indirect-lease-dates-missing';
			}
			if (!creditSubject) {
				return 'pending-setup';
			}
			if (
				residency.residentEndDate &&
				creditSubject.rentReportingStatus === CreditSubjectRentReportingStatus.Active &&
				typedIncludes(verifiedLeaseAgreementStatuses, leaseAgreementStatus)
			) {
				const leaseEndDate = isoDateToJsDate(residency.residentEndDate);
				const now = new Date();
				const diff = differenceInDays(leaseEndDate, now);
				if (diff > 0 && diff < 30) {
					return 'indirect-last-month-of-lease';
				}
			}
		}

		const hasExecutingPayment = creditSubject?.payments.some(
			(payment) => payment.status === TransactionStatus.Executing,
		);
		if (unpaid && !hasExecutingPayment) {
			return 'pending-payment';
		} else if (pendingCreditBuilderConfirmation) {
			return 'pending-confirmation';
		} else if (leaseAgreementUploadPending) {
			return 'upload-lease-agreement-pending';
		} else if (leaseAgreementStatus === ManualResidencyLeaseAgreementStatus.UpdateRequested) {
			return 'lease-agreement-update-requested';
		}

		return null;
	},
	getOnClickLinkHandler(
		navigate: NavigateFunction,
		input: { errorState: CreditBuilderErrorState | null; creditSubject?: CreditSubject },
	) {
		const { errorState, creditSubject } = input;
		const { payment } = getPaymentFromGetInfo(creditSubject);
		const pay = trpc.creditReporting.pay.useMutation();

		const payOrTryAgain = () => {
			if (payment) {
				navigate(links.ACCOUNT.CREDIT_BUILDER.SUCCESS);
			} else {
				pay.mutate(undefined, {
					onSuccess() {
						navigate(links.ACCOUNT.CREDIT_BUILDER.SUCCESS);
					},
				});
			}
		};
		if (!errorState) {
			return null;
		}

		switch (errorState) {
			case 'upload-lease-agreement-pending':
				return () => navigate(links.ACCOUNT.CREDIT_BUILDER.SETUP);
			case 'pending-payment':
				return () => payOrTryAgain();
			case 'pending-confirmation':
				return () => navigate(links.ACCOUNT.CREDIT_BUILDER.SUCCESS);
			case 'lease-agreement-update-requested':
				return () => navigate(links.ACCOUNT.UPLOAD_LEASE_AGREEMENTS);
			case 'indirect-rent-amount-missing':
			case 'pending-setup':
			case 'indirect-lease-dates-missing':
				return () => navigate(links.ACCOUNT.CREDIT_BUILDER.SETUP);
			case 'indirect-last-month-of-lease':
				return () => navigate(links.ACCOUNT.CREDIT_BUILDER.INDIRECT_LAST_MONTH_OF_LEASE);
			default:
				ensureExhaustiveCheck(errorState);
		}
	},

	indirectVerificationPending(input: {
		userVerified: boolean;
		indirectModel: boolean;
		leaseAgreementUploadPending: boolean;
	}) {
		return !input.userVerified && (!input.leaseAgreementUploadPending || input.indirectModel);
	},
};
