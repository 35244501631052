import React, { useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import connectBank from 'url:../../static/shapes/connect-bank.png';
import z from 'zod';

import { RentMonth } from '../../../../../functions/src/shared/rent-month';

import { FormattedCents } from '../../../shared/components/formatted-cents';
import { FormattedRentMonth } from '../../../shared/components/formatted-rent-month';
import { trpc } from '../../../shared/trpc/client';

import { Block, Button, Center, Footer, FormInput, Spacing, Text } from '../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../base-ui/components/Alert/Alert';
import { Configuration } from '../../../base-ui/components/Icon/Icons';
import { colors } from '../../../base-ui/variables';

import { BackButton } from '../../components/back-button';
import { useInterface, useUserData } from '../../contexts/hooks';
import { links } from '../../Router/paths';

const ErrorMessage = styled.div`
	color: ${colors.error};
`;

const ConfirmModalIllustration = styled.div`
	width: 6rem;
	height: 6rem;
	background: ${colors.green};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin: 0 auto 1.5rem;
`;

export const DisableCustomRent = () => {
	const navigate = useNavigate();

	const { setModal, closeModal } = useInterface();
	const {
		handlers: { forceRefetch },
		home,
	} = useUserData();
	const intl = useIntl();

	const { schema, otherReason, residencyChangeReason, reasonsOptions } = useMemo(() => {
		const otherReasonMemo = intl.formatMessage({
			id: 'disable-custom-rent.other-reason',
			defaultMessage: 'Other',
		});
		const residencyChangeReasonMemo = intl.formatMessage({
			id: 'disable-custom-rent.residency-change-reason',
			defaultMessage: 'I’m moving out',
		});

		const reasonsOptionsMemo = [
			residencyChangeReasonMemo,
			intl.formatMessage({
				id: 'disable-custom-rent.dont-need-it',
				defaultMessage: 'I don’t need it right now',
			}),
			intl.formatMessage({
				id: 'disable-custom-rent.not-happy-reason',
				defaultMessage: 'I’m not happy with the service',
			}),
			otherReasonMemo,
		];

		const schemaMemo = z
			.object({
				reason: z.string({
					required_error: intl.formatMessage({
						id: 'disable-custom-rent.reason-required',
						defaultMessage: 'Please choose one of the following options',
					}),
				}),
				reasonOther: z.string().optional(),
			})
			.refine(({ reason, reasonOther }) => !(reason === otherReasonMemo && !reasonOther), {
				message: intl.formatMessage({
					id: 'common.required',
					defaultMessage: 'Required',
				}),
				path: ['reasonOther'],
			});
		return {
			otherReason: otherReasonMemo,
			residencyChangeReason: residencyChangeReasonMemo,
			reasonsOptions: reasonsOptionsMemo,
			schema: schemaMemo,
		};
	}, [intl]);
	type Schema = z.infer<typeof schema>;

	const [residencyChangeConfirm, setResidencyChangeConfirm] = useState(false);

	const customRentDisableSurvey = trpc.user.customRent.disableSurvey.useMutation();
	const customRentDisable = trpc.user.customRent.disable.useMutation({
		async onSuccess() {
			await forceRefetch();
			closeModal();
		},
	});
	const getBalance = trpc.user.getBalance.useQuery();

	const rentThatCanBeDisabled = RentMonth.fromDbParams(
		home?.rentThatCanBeDisabled ?? RentMonth.current().next().asDbParams(),
	);

	const handleDisable = async (reason: Schema) => {
		customRentDisable.mutate(reason);
	};

	// --- form ---
	const form = useForm<Schema>({
		resolver: zodResolver(schema),
	});

	const openConfirmationModal = (payload: Schema) => {
		setModal(
			<>
				<ConfirmModalIllustration>
					<Configuration fill={colors.white} />
				</ConfirmModalIllustration>

				<Text type="title" center>
					<FormattedMessage defaultMessage="Please confirm" id="disable-custom-rent.confirm-title" />
				</Text>
				<Spacing $size="m" />

				{getBalance.data && getBalance.data.balanceCents < 0 && (
					<>
						<Text center>
							<FormattedMessage
								defaultMessage="You are still responsible for {balance} money you owe to Zenbase."
								id="disable-custom-rent.balance-message"
								values={{
									balance: <FormattedCents value={-1 * getBalance.data.balanceCents} />,
								}}
							/>
						</Text>
						<Spacing $size="m" />
					</>
				)}

				<Text center>
					<FormattedMessage
						defaultMessage="Starting from {rentMonth} you will need to pay your landlord directly."
						id="disable-custom-rent.starting-from"
						values={{
							rentMonth: <FormattedRentMonth rentMonth={rentThatCanBeDisabled} />,
						}}
					/>
				</Text>

				<Spacing $size="m" />
				<Text center>
					<FormattedMessage
						defaultMessage="If you have any questions please message the Zenbase team."
						id="disable-custom-rent.questions-message"
					/>
				</Text>

				<Spacing $size="l" />

				<Footer>
					<Button disabled={customRentDisable.isLoading} onClick={() => handleDisable(payload)}>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
					<Button
						type="link"
						onClick={() => {
							closeModal();
							navigate(links.ACCOUNT.DASHBOARD);
						}}
						disabled={customRentDisable.isLoading}
					>
						<FormattedMessage defaultMessage="Cancel" id="common.cancel" />
					</Button>
				</Footer>
			</>,
		);
	};

	const submit = (payload: Schema) => {
		if (payload.reason === residencyChangeReason) {
			return setResidencyChangeConfirm(true);
		}

		openConfirmationModal(payload);
	};

	if (customRentDisable.isError) {
		return <UnexpectedErrorAlert />;
	}

	if (customRentDisable.isSuccess) {
		return (
			<>
				<BackButton to={links.ACCOUNT.DASHBOARD} />
				<Text center type="title">
					<FormattedMessage defaultMessage="CustomRent is Disabled" id="disable-custom-rent.disabled-title" />
				</Text>
				<Spacing $size="m" />
				<Text center>
					<FormattedMessage
						defaultMessage="Starting next month, you will be on your original rent schedule. If you have any questions or feedback message us."
						id="disable-custom-rent.disabled-message"
					/>
				</Text>
				<Spacing $size="l" />
				<Footer>
					<Button
						onClick={() => {
							navigate(links.ACCOUNT.DASHBOARD);
						}}
					>
						<FormattedMessage defaultMessage="Return Home" id="disable-custom-rent.return-home-button" />
					</Button>
				</Footer>
			</>
		);
	}

	if (residencyChangeConfirm) {
		return (
			<>
				<BackButton onClick={() => setResidencyChangeConfirm(false)} />

				<Text type="title">
					<FormattedMessage
						defaultMessage="Would you like to continue using Zenbase?"
						id="disable-custom-rent.continue-using-zenbase-title"
					/>
				</Text>
				<Spacing $size="l" />

				<Center>
					<img alt="Connect bank" src={connectBank} width="295px" height="177px" />
				</Center>
				<Spacing $size="xl" />

				<Text>
					<FormattedMessage
						defaultMessage="We are always here to help so please let us know if you would like to continue using Zenbase at your new home!"
						id="disable-custom-rent.continue-using-zenbase-message"
					/>
				</Text>
				<Spacing $size="m" />

				<Footer>
					<Button
						onClick={async () => {
							const done = () => navigate(links.ACCOUNT.RESIDENCY.SELECT_PROPERTY);
							await customRentDisableSurvey.mutateAsync(
								{ reason: residencyChangeReason, continueUsingZenbase: true },
								{ onSuccess: done, onError: done },
							);
						}}
					>
						<FormattedMessage defaultMessage="Yes" id="common.yes" />
					</Button>
					<Button type="link" onClick={() => openConfirmationModal({ reason: residencyChangeReason })}>
						<FormattedMessage defaultMessage="No" id="common.no" />
					</Button>
				</Footer>
			</>
		);
	}

	return (
		<>
			<BackButton />
			<Text type="title">
				<FormattedMessage
					defaultMessage="Could you confirm why you’d like to disable CustomRent?"
					id="disable-custom-rent.confirm-reason-title"
				/>
			</Text>
			<Spacing $size="m" />
			<Text>
				<FormattedMessage
					defaultMessage="Please select the one that resonates most with you."
					id="disable-custom-rent.select-reason-message"
				/>
			</Text>
			<Spacing $size="m" />

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(submit)}>
					<Controller
						name="reason"
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<>
								{reasonsOptions.map((option) => (
									<Block
										onClick={() => onChange(option)}
										title={option}
										selected={value === option}
										key={option}
									>
										{option === otherReason && value === otherReason && (
											<FormInput
												name="reasonOther"
												placeholder={intl.formatMessage({
													defaultMessage: 'Please describe your reason',
													id: 'disable-custom-rent.reason-other-placeholder',
												})}
											/>
										)}
									</Block>
								))}
								{error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
							</>
						)}
					/>

					<Footer>
						<Button htmlType="submit">
							<FormattedMessage defaultMessage="Continue" id="common.continue" />
						</Button>
					</Footer>
				</form>
			</FormProvider>
		</>
	);
};
