import React, { useState } from 'react';
import { FormattedDate, FormattedNumber, useIntl } from 'react-intl';

import { centsToAmount } from '../../../../../../functions/src/shared/monetary';
import { RentMonth } from '../../../../../../functions/src/shared/rent-month';

import { trpc } from '../../../../shared/trpc/client';

import { PaymentDatePanel } from '../../payment-date-panel/payment-date-panel';
import { Option, PaymentWrapper, ToggleMonth } from './scheduled-payments';

export const ScheduledLandlordPayments = () => {
	const { data: charges, isLoading } = trpc.user.listNextTwoLeaseCharges.useQuery(undefined, {
		refetchOnWindowFocus: false,
	});

	const [nextMonthActive, setNextMonthActive] = useState(false);

	const handleSwitch = (value: boolean) => () => {
		setNextMonthActive(value);
	};

	if (isLoading || !charges?.length) return null;

	const currentMonth = RentMonth.fromDbParams(charges[0]);
	const nextMonth = charges[1] ? RentMonth.fromDbParams(charges[1]) : undefined;

	const selectedCharge = charges[nextMonthActive ? 1 : 0];

	const intl = useIntl();

	return (
		<div>
			<ToggleMonth $nextMonthActive={nextMonthActive}>
				<Option onClick={handleSwitch(false)} $active={!nextMonthActive}>
					<FormattedDate value={currentMonth.firstDayAsDate()} month="long" />
				</Option>
				{nextMonth && (
					<Option onClick={handleSwitch(true)} $active={nextMonthActive}>
						<FormattedDate value={nextMonth.firstDayAsDate()} month="long" />
					</Option>
				)}
			</ToggleMonth>
			<PaymentWrapper>
				<PaymentDatePanel
					date={RentMonth.fromDbParams(selectedCharge).firstDayAsDate()}
					amount={
						<FormattedNumber
							value={centsToAmount(selectedCharge.amountCents)}
							currency="CAD"
							style="currency"
						/>
					}
					isEditable={false}
					rentMonth={RentMonth.fromDbParams(selectedCharge)}
					label={intl.formatMessage({
						defaultMessage: 'You pay your landlord',
						id: 'scheduled-landlord-payments.you-pay-your-landlord',
					})}
					canChangeDateSince={new Date()}
				/>
			</PaymentWrapper>
		</div>
	);
};
