import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';

export const AddDebitCardWrapper = ({ children, backLink }: { children: ReactNode; backLink: string }) => (
	<>
		<BackButton to={backLink} />
		<Text type="title">
			<FormattedMessage
				defaultMessage="Enter cardholder address"
				id="add-debit-card-wrapper.enter-cardholder-address"
			/>
		</Text>
		{children}
	</>
);
