import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import congrats from 'url:../../static/shapes/congrats.png';
import learning from 'url:../../static/shapes/learning.png';

import { publicConfig } from '../../../../../functions/src/shared/config';

import { trpc } from '../../../shared/trpc/client';

import { Button, Footer, Spacing } from '../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../base-ui/components/Alert/Alert';

import { AlertError } from '../../components/alert-error';
import { ImgPreload } from '../../components/img-preload';
import { useInterface } from '../../contexts/hooks';
import { links } from '../../Router/paths';
import { ConfirmCustomRentCannotEnrollForMonth } from '../../templates/confirm-custom-rent-cannot-enroll-for-month';
import { ConfirmRentContent } from '../../templates/confirm-rent-content';
import { getCustomRentStartRentMonth } from '../../utils/get-custom-rent-start-month';

export const ConfirmCustomRent = () => {
	const { setModal, closeModal } = useInterface();

	const utils = trpc.useUtils();
	const getConfig = trpc.user.customRent.getConfig.useQuery(undefined, {
		// reuse config loaded by previous steps - mutations will cache the new one
		staleTime: Infinity,
	});
	const { data: home } = trpc.user.home.useQuery(undefined);

	const confirmConfig = trpc.user.customRent.confirmConfig.useMutation({
		onSuccess: () => {
			// home data with expected payments
			utils.invalidate();
			// navigated away by changed user status
		},
	});

	// user has no config at all
	if (getConfig.data === null) {
		return <Navigate to={links.REGISTRATION.CONFIGURE.SELECT_RENT_AMOUNT} />;
	}

	if (getConfig.isInitialLoading) {
		return null;
	}
	if (getConfig.isError || getConfig.data === undefined) {
		return <UnexpectedErrorAlert />;
	}

	const customRentStartRentMonth = getCustomRentStartRentMonth(getConfig.data, home);
	const config = { ...getConfig.data, rentMonth: customRentStartRentMonth.asDbParams() };

	const handleClick = (useNextRentMonth?: boolean) => {
		if (!config) return;

		let rentMonth = customRentStartRentMonth;
		if (useNextRentMonth) {
			rentMonth = rentMonth.next();
		}

		confirmConfig.mutate(
			{ rentMonth: rentMonth.asDbParams() },
			{
				onError(err) {
					if (err.shape?.message.includes(publicConfig.errorMessages.cannotEnrollForMonth)) {
						setModal(
							<ConfirmCustomRentCannotEnrollForMonth
								rentMonth={rentMonth}
								onContinue={() => {
									closeModal();
									handleClick(true);
								}}
							/>,
						);
					}
				},
			},
		);
	};

	return (
		<>
			{config && <ConfirmRentContent config={config} />}
			<Spacing $size="xl" />

			{confirmConfig.isError &&
				!confirmConfig.error.shape?.message.includes(publicConfig.errorMessages.cannotEnrollForMonth) && (
					<AlertError />
				)}

			<Footer stickyOnMobile>
				<Button
					disabled={!config || confirmConfig.isLoading}
					onClick={handleClick}
					testId="confirm-custom-rent-confirm-button"
				>
					<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
				</Button>
			</Footer>

			{/* preload first success step images */}
			<ImgPreload src={congrats} />
			<ImgPreload src={learning} />
		</>
	);
};
