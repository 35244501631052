import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { To, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors } from '../../variables';

type ButtonType = 'large' | 'large-error' | 'large-ternary' | 'link' | 'icon' | 'small' | 'icon-transparent';

export type ButtonProps = PropsWithChildren<{
	disabled?: boolean;
	type?: ButtonType;
	htmlType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
	onClick?: () => void;
	linkTo?: To;
	linkState?: unknown;
	testId?: string;
	className?: string;
	transparent?: boolean;
	noPadding?: boolean;
}>;

export const Button = (props: ButtonProps) => {
	const {
		disabled,
		type = 'large',
		onClick,
		linkTo,
		linkState,
		htmlType = 'button',
		children,
		testId,
		className,
	} = props;

	const navigate = useNavigate();

	const handleClick = () => {
		if (linkTo) {
			return navigate(linkTo, { state: linkState });
		}
		if (onClick) {
			onClick();
		}
	};

	return (
		<Element
			disabled={disabled}
			buttonType={type}
			type={htmlType}
			onClick={handleClick}
			data-testid={testId}
			className={className}
			transparent={props.transparent}
			noPadding={props.noPadding}
		>
			{children}
		</Element>
	);
};

const buttonFont = css`
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 2.6rem;
	@media (max-width: 450px) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`;

const Element = styled.button<Omit<ButtonProps, 'type'> & { buttonType: ButtonType }>`
	margin: 8px 0;
	color: ${colors.white};
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 3rem;
	border: none;
	max-width: 33.5rem;
	background-color: ${({ disabled, buttonType, transparent }) => {
		if (buttonType === 'icon-transparent' || transparent) return 'transparent';
		if (disabled) return colors.bgDisabled;

		if (buttonType === 'large-error') {
			return colors.red;
		}

		if (buttonType === 'large-ternary') {
			return colors.teal;
		}

		return disabled ? colors.bgDisabled : colors.green;
	}};
	/* pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')}; */
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	width: ${({ buttonType }) => (buttonType === 'small' ? 'auto' : '100%')};
	padding: ${({ buttonType, noPadding }) => {
		if (noPadding) return '0';
		if (buttonType === 'small') {
			return '0.7rem 3rem 0.9rem 3rem';
		}
		return '1rem 3rem 1.4rem 3rem';
	}};
	transition: background-color 150ms ease-in-out;

	${buttonFont}

	&:hover {
		/* background-color: ${colors.greenHover}; */
		background-color: ${({ disabled, buttonType, transparent }) => {
			if (buttonType === 'icon-transparent' || transparent) return 'transparent';
			if (disabled) return colors.bgDisabled;

			if (buttonType === 'large-error') {
				return colors.redHover;
			}

			if (buttonType === 'large-ternary') {
				return colors.tealHover;
			}

			return colors.greenHover;
		}};
	}

	${(props) =>
		props.buttonType === 'link' &&
		css`
			background: none;
			color: ${colors.green};
			text-decoration: underline;
			width: 100%;
			&:hover {
				background: none;
				color: ${colors.greenHover};
			}
		`}

	a,
	a:hover,
	a:visited {
		text-decoration: none;
		color: inherit;
	}
`;
