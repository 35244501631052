import React from 'react';
import { FormattedMessage } from 'react-intl';

import { RouterOutput } from '../../../../../functions/src/api/trpc/types';

import { PositiveModal } from '../../../base-ui/components';

export function CardAddedModal(props: {
	result: RouterOutput['user']['paymentCards']['createZumRailsDebitCard'];
	buttonHandler: () => void;
}) {
	return (
		<PositiveModal
			headerText={<FormattedMessage defaultMessage="Card added" id="card-added-modal.card-added" />}
			contentText={
				<>
					<FormattedMessage
						defaultMessage="Debit card ending with {mask} was added successfully."
						id="card-added-modal.card-added-success"
						values={{ mask: props.result.mask }}
					/>
				</>
			}
			buttonText={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
			buttonHandler={props.buttonHandler}
		/>
	);
}
