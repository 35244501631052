import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import success from 'url:../../static/shapes/connect-bank.png';

import { Box, Spacing } from '../../../base-ui/components';

import { OnboardingSuccess } from '../../templates/onboarding-success';

export const DirectDebitCardSuccess = () => {
	const navigate = useNavigate();

	function handleNext() {
		navigate('/');
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={success} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Debit card saved!"
							id="custom-rent-onboarding.direct-debit-success.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Your debit card was succesfully saved. You were not charged yet. We will charge your card automatically when your rent is due."
							id="custom-rent-onboarding.direct-debit-success.text"
						/>
					}
					cta={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
					onNext={handleNext}
					ctaTestId="direct-debit-card-success-continue-button"
					backButton={false}
				/>
			</Box>
		</>
	);
};
