import React, { Ref } from 'react';
import styled from 'styled-components';

import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { UseAutocompleteProps } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';

import { Input } from '.';
import { colors } from '../variables';

type Props<
	T,
	Multiple extends boolean | undefined = undefined,
	DisableClearable extends boolean | undefined = undefined,
	FreeSolo extends boolean | undefined = undefined,
> = {
	label: string;
	error?: string;
	testId?: string;
	autocomplete: UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;
};

export const AutoSuggestInput = <
	T,
	Multiple extends boolean | undefined = undefined,
	DisableClearable extends boolean | undefined = undefined,
	FreeSolo extends boolean | undefined = undefined,
>({
	label,
	error,
	testId,
	autocomplete,
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
	const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
		...autocomplete,
	});

	const { ref, ...inputProps } = getInputProps() as ReturnType<typeof getInputProps> & {
		value: string;
		ref: Ref<HTMLInputElement>;
	};

	return (
		<Wrapper>
			<div {...getRootProps()}>
				<InputElement
					errorText={error}
					placeholder={label}
					ref={ref}
					testId={testId}
					value={inputProps.value}
					rawInputProps={inputProps}
				/>
			</div>
			{groupedOptions.length > 0 ? (
				<Listbox {...getListboxProps()}>
					{groupedOptions.map((option, index) => (
						<li key={index} {...getOptionProps({ option, index })} data-testid={`${testId}-${option}`}>
							{autocomplete.getOptionLabel ? autocomplete.getOptionLabel(option) : option.name}
						</li>
					))}
				</Listbox>
			) : null}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	position: relative;
`;

const InputElement = styled(Input)``;

const Listbox = styled.ul`
	position: absolute;
	left: 0;
	right: 0;
	z-index: 10;
	max-height: 20rem;
	overflow: auto;
	width: 100%;
	background: ${colors.white};
	border-radius: 1rem;
	box-shadow: 0px 0px 20px rgb(23 23 23 / 10%);
	border: 1px solid #e4e4e4;

	margin-top: -2.2rem;
	@media (max-width: 450px) {
		margin-top: -2rem;
	}

	& li {
		display: block;
		padding: 1.5rem 1rem 1.5rem 4rem;
		border-bottom: 1px solid #f7f7f7;

		@media (max-width: 450px) {
			padding: 1rem 1rem 1rem 3rem;
		}

		&:hover,
		&.${autocompleteClasses.focused} {
			background: ${colors.lightGray};
		}
	}
`;
