import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { z } from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { jsDateToIsoDate } from '../../../../functions/src/shared/iso-date';
import { RentMonth } from '../../../../functions/src/shared/rent-month';

import { FormattedCents } from '../../shared/components/formatted-cents';
import { formatRentMonthIntl } from '../../shared/components/formatted-rent-month';
import { trpc } from '../../shared/trpc/client';

import {
	RadioGroup as BaseRadioGroup,
	Block,
	Button,
	Footer,
	FormInput,
	Loader,
	Spacing,
	Text,
} from '../../base-ui/components';
import { ScrollableBlockList } from '../../base-ui/components/Block/Block';
import { ErrorMessage } from '../../base-ui/components/Radio/styles';

import { AlertError } from '../components/alert-error';

const schema = z.object({
	transactionId: z.string().optional(),
	note: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

export const RadioGroup = styled(BaseRadioGroup)`
	margin: 3em 0 1em;
	grid-template-columns: 1fr;

	overflow: auto;
	max-height: 200rem;
`;

type Props = {
	onNext: () => void;
	onConnectDifferentAccount: () => void;
};

export const ConfirmRentPaymentOnboarding = ({ onNext, onConnectDifferentAccount }: Props) => {
	const trackOnboardingEvent = trpc.user.trackOnboardingEvent.useMutation();
	useEffect(() => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingRentPaymentConfirmationStart });
	}, []);
	return <ConfirmRentPayment onNext={onNext} onConnectDifferentAccount={onConnectDifferentAccount} />;
};

export const ConfirmRentPayment = ({ onNext, onConnectDifferentAccount }: Props) => {
	const intl = useIntl(); // Add this line to get the intl object
	const list = trpc.user.listTransactionsForRentPaymentConfirmation.useQuery();
	const confirm = trpc.user.rentPaymentConfirmation.useMutation({
		onSuccess() {
			onNext();
		},
	});

	const isError = list.isError || confirm.isError;

	const form = useForm<FormData>({
		resolver: zodResolver(schema),
	});

	const submit = async ({ transactionId, note }: FormData) => {
		confirm.mutate({ transactionId, note });
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<Text type="title">
					<FormattedMessage
						id="confirm-rent-payment.confirm-title"
						defaultMessage="Confirm your rent payment"
					/>
				</Text>

				<Spacing $size="s" />
				<Text type="small">
					<FormattedMessage
						id="confirm-rent-payment.confirm-subtitle"
						defaultMessage="Please confirm your latest rent payment."
					/>
				</Text>
				<Spacing $size="m" />

				{isError && <AlertError />}
				{list.isLoading && (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				)}

				<Spacing $size="m" />
				<Controller
					name="transactionId"
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<ScrollableBlockList>
							{list.data?.map(({ id, amountCents, name, transactionDate }) => (
								<Block
									onClick={() => onChange(id)}
									title={
										<>
											{name} {jsDateToIsoDate(transactionDate)}
										</>
									}
									selected={value === id}
									key={id}
								>
									<AmountLabel>
										<FormattedCents value={amountCents} />
									</AmountLabel>
								</Block>
							))}
							{error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
						</ScrollableBlockList>
					)}
				/>

				{!list.data?.length && list.isFetched && (
					<Text>
						<FormattedMessage
							id="confirm-rent-payment.no-transactions-found"
							defaultMessage="No transactions found for {prevRentMonth}. Please tell us how you paid your rent in the note below."
							values={{ prevRentMonth: formatRentMonthIntl(intl, RentMonth.current().prev()) }}
						/>
					</Text>
				)}

				<Spacing $size="m" />
				<FormInput
					name="note"
					placeholder={intl.formatMessage({
						id: 'confirm-rent-payment.note-placeholder',
						defaultMessage: 'Note',
					})}
				/>

				<Footer>
					<Button htmlType="submit" disabled={confirm.isLoading}>
						<FormattedMessage id="common.confirm" defaultMessage="Confirm" />
					</Button>
					<Button type="link" onClick={onConnectDifferentAccount}>
						<FormattedMessage
							id="confirm-rent-payment.connect-different-account"
							defaultMessage="Connect to a different account"
						/>
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};

const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
`;

const AmountLabel = styled.p`
	font-size: 2.4rem;
	margin-top: 1rem;
`;
