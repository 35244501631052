import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import image from 'url:../../static/shapes/support.png';

import { Box, Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { useBankOnboarding } from '../../contexts/hooks';

const StyledImage = styled.div`
	display: block;
	left: 0;
	position: relative;
	top: -4rem;
	width: calc(100% + 10rem);
	@media (max-width: 450px) {
		width: calc(100% + 4rem);
		top: -2rem;
	}
`;

export const MicroDepositPending = () => {
	const [isLoading, setLoading] = useState(false);
	const { handleBankOnboardingCompleted } = useBankOnboarding();
	const handleClick = async () => {
		setLoading(true);
		try {
			await handleBankOnboardingCompleted();
		} catch {
			// if there was no error, user is navigated away
			setLoading(false);
		}
	};

	return (
		<Box isColumn verticalCenter>
			<StyledImage>
				<img alt="Connect bank" src={image} />
			</StyledImage>
			<Spacing $size="s" />
			<Text type="title">
				<FormattedMessage
					defaultMessage="Bank account verification pending"
					id="micro-deposit-pending.bank-account-verification-pending"
				/>
			</Text>
			<Spacing $size="l" />

			<Text type="small">
				<FormattedMessage
					defaultMessage="We will send you two micro deposit payments to verify which will appear within two business days."
					id="micro-deposit-pending.micro-deposit-payments"
				/>
			</Text>
			<Footer>
				<Button disabled={isLoading} onClick={handleClick}>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Footer>
		</Box>
	);
};
