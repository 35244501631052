import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { FormattedMessage } from 'react-intl';
import { Navigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { publicConfig } from '../../../../functions/src/shared/config';
import { TransactionStatus } from '../../../../functions/src/shared/payment-transaction';

import { getEnvironment } from '../../shared/environment';
import { queryClient, trpc } from '../../shared/trpc/client';

import { Loader, Spacing, Text } from '../../base-ui/components';

import { BackButton } from '../components/back-button';
import { links } from '../Router/paths';

export const PaymentStatus = () => {
	const [search] = useSearchParams();
	const successPath = search.get('success');
	const ptid = search.get('ptid');

	const [stripePromise] = useState(() => loadStripe(publicConfig.stripe[getEnvironment()].publicKey));
	const [result, setResult] = useState<'success' | 'processing' | 'error'>();

	const [paymentStatusRefetchLimit, setPaymentStatusRefetchLimit] = useState(false);
	const paymentStatus = trpc.user.getPaymentStatus.useQuery(
		{ ptid: ptid ?? '' },
		{
			enabled: Boolean(ptid && result === 'success'),
			refetchInterval: (_data, { state: { dataUpdateCount } }) => (dataUpdateCount > 10 ? false : 2000),
			onSettled() {
				const state = queryClient.getQueryState([
					['user', 'getPaymentStatus'],
					{ input: { ptid }, type: 'query' },
				]);
				if ((state?.dataUpdateCount ?? 0) > 10) {
					setPaymentStatusRefetchLimit(true);
				}
			},
		},
	);

	const loadIntentStatus = async () => {
		const clientSecret = search.get('payment_intent_client_secret');
		const stripe = await stripePromise;
		if (!clientSecret || !stripe) {
			Sentry.captureException(new Error('clientSecret or stripe missing'));
			return;
		}

		const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
		switch (paymentIntent?.status) {
			case 'succeeded':
				setResult('success');
				break;

			case 'processing':
				setResult('processing');
				break;

			case 'requires_payment_method':
				// Redirect your user back to your payment page to attempt collecting
				// payment again
				// 'Failed to process payment details. Please try another payment method.'
				setResult('error');
				break;

			default:
				Sentry.captureException(
					new Error(`unknown paymentIntent ${clientSecret} status: ${paymentIntent?.status}`),
				);
		}
	};

	useEffect(() => {
		loadIntentStatus();
	}, []);

	if (result === 'success') {
		if (successPath) {
			if (paymentStatus.data?.status === TransactionStatus.Completed || paymentStatusRefetchLimit) {
				return <Navigate to={{ pathname: successPath, search: `?payment=success` }} />;
			}
			return (
				<FormLoader>
					<Loader />
				</FormLoader>
			);
		}

		return (
			<>
				<BackButton to={links.ACCOUNT.DASHBOARD}></BackButton>
				<Text type="title">
					<FormattedMessage defaultMessage="Payment successful!" id="payment-status.payment-successful" />
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage
						defaultMessage="Your payment was processed."
						id="payment-status.payment-processed"
					/>
				</Text>
				<Spacing $size="l" />
			</>
		);
	}

	if (result === 'error') {
		return (
			<>
				<BackButton to={links.ACCOUNT.DASHBOARD}></BackButton>
				<Text type="title">
					<FormattedMessage defaultMessage="Processing error" id="payment-status.processing-error" />
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage defaultMessage="Please contact support." id="payment-status.contact-support" />
				</Text>
				<Spacing $size="l" />
			</>
		);
	}

	// this should not really happen unless we enable bank transfer payments for Stripe
	if (result === 'processing') {
		return (
			<>
				<BackButton to={links.ACCOUNT.DASHBOARD}></BackButton>
				<Text type="title">
					<FormattedMessage defaultMessage="Processing payment" id="payment-status.processing-payment" />
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage
						defaultMessage="Your payment is still processing. For some payment methods, this make take up to two business days."
						id="payment-status.payment-processing"
					/>
				</Text>
				<Spacing $size="l" />
			</>
		);
	}

	if (!result && successPath) {
		return <Navigate to={{ pathname: successPath, search: `?payment=success` }} />;
	}

	if (successPath) {
		return null;
	}

	return (
		<>
			<BackButton />
			<Text type="title">
				<FormattedMessage defaultMessage="Loading payment..." id="payment-status.loading-payment" />
			</Text>
			<FormLoader>
				<Loader />
			</FormLoader>
		</>
	);
};

const FormLoader = styled.div`
	position: absolute;
	z-index: 0;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;
