import React, { useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import z from 'zod';

import { InviteType } from '../../../../../functions/src/shared/partner';
import { SignUpType } from '../../../../../functions/src/shared/user';

import { dirtyValues } from '../../../shared/form';
import { trpc } from '../../../shared/trpc/client';
import { useLocalStorage } from '../../../shared/use-local-storage';

import { Alert, AlertText, AlertTitle, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { SignInMethods } from '../../contexts/Firebase';
import { firebaseErrors } from '../../contexts/Firebase/firebaseApi';
import { useFirebase } from '../../contexts/hooks';
import { Loader } from '../../contexts/Interface/Loader/Loader';
import { links } from '../../Router/paths';
import { clearInvite, getInvite } from '../../utils/invite-storage';
import { SignUpForm } from './sign-up-form';
import { getSignUpFormSchema, SignUpFormSchema } from './sign-up-form-service';

type Props = {
	type?: SignUpType;
};

export const SignUp = ({ type: signUpType }: Props) => {
	const {
		handlers: { handlePasswordReset, handleSignIn, handleSignUp },
	} = useFirebase();

	const { referralCode } = useParams();
	const intl = useIntl();
	const [referralCodeCache, setReferralCodeCache] = useLocalStorage(
		z.object({
			referralCode: z.string().optional().nullish(),
		}),
		'zenbase-referral-code',
		{},
	);
	useEffect(() => {
		if (!referralCode) return;
		setReferralCodeCache({ referralCode });
	}, [referralCode]);

	const [inviteSession] = useState(getInvite);
	const createUserByInvite = trpc.public.createUserByInvite.useMutation();
	const isInvite = Boolean(inviteSession?.inviteId && inviteSession.inviteSecret);
	const inviteData = trpc.public.getInviteDetails.useQuery(
		{ invite: inviteSession?.inviteId || '', secret: inviteSession?.inviteSecret || '' },
		{
			enabled: isInvite,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (inviteData.data?.status === 'found') {
			const data = inviteData.data;
			form.reset({
				firstName: data.firstName || undefined,
				lastName: data.lastName || undefined,
				email: data.email,
				phone: data.phone ? parsePhoneNumber(data.phone, 'US')?.number || data.phone : undefined,
			});
		}
	}, [inviteData.data]);

	const createVerifiedUser = async (
		{ firstName, lastName, email, password, phone }: SignUpFormSchema,
		invite: string,
		secret: string,
	) => {
		const result = await createUserByInvite.mutateAsync({
			invite,
			secret,
			firstName,
			lastName,
			phone,
			locale: intl.locale,
		});
		if (result.status !== 'created' || !result.passwordResetCode) throw result.status;

		await handlePasswordReset(result.passwordResetCode, password);
		await handleSignIn({ method: SignInMethods.email, email, password });

		clearInvite();
	};
	const schema = useMemo(() => getSignUpFormSchema(intl), [intl]);

	const form = useForm<SignUpFormSchema>({ resolver: zodResolver(schema) });

	const [error, setError] = useState<unknown>();
	const handleSubmit = async (formData: SignUpFormSchema) => {
		setError(undefined);

		const changedFields = dirtyValues(form.formState.dirtyFields, formData);
		if (
			inviteData.data &&
			// TODO: trpc migration
			'email' in inviteData.data &&
			inviteData.data.email &&
			inviteSession?.inviteId &&
			inviteSession.inviteSecret &&
			!('email' in changedFields)
		) {
			await createVerifiedUser(formData, inviteSession.inviteId, inviteSession.inviteSecret).catch((pError) => {
				setError(pError);
			});
			return;
		}

		await handleSignUp({
			...formData,
			signUpType,
			referralCode: referralCodeCache.referralCode,
			locale: intl.locale,
		}).catch((pError) => {
			if (pError.code === firebaseErrors.ERROR_CODE_EMAIL_ALREADY_IN_USE) {
				return form.setError('email', {
					message: intl.formatMessage({
						defaultMessage: 'Email already in use',
						id: 'sign-up.email-already-in-use',
					}),
				});
			}

			setError(pError);
		});
	};

	const unifiedPayments =
		inviteData.data?.status === 'found' && inviteData.data.type === InviteType.PartnerUnifiedPayments;

	return (
		<>
			<BackButton testId="sign-up-back-button" to={links.DEFAULT.ROOT} />

			{!(isInvite && inviteData.isLoading) && (
				<>
					<Text type="title" testId="sign-up-title">
						{unifiedPayments ? (
							<FormattedMessage defaultMessage="Payments profile" id="sign-up.payments-profile" />
						) : signUpType === SignUpType.CreditBuilder ? (
							<FormattedMessage
								defaultMessage="Sign Up for CreditBuilder"
								id="sign-up.sign-up-for-credit-builder"
							/>
						) : (
							<FormattedMessage defaultMessage="Sign Up" id="common.sign-up" />
						)}
					</Text>
					<Spacing $size="s" />
					<Text>
						{unifiedPayments ? (
							<FormattedMessage
								defaultMessage="Please review your details and add a password."
								id="sign-up.review-details"
							/>
						) : (
							<FormattedMessage
								defaultMessage="We will never share your information with anyone."
								id="sign-up.we-will-never-share"
							/>
						)}
					</Text>
					<Spacing $size="m" />
				</>
			)}

			{!!error && (
				<Alert>
					<AlertTitle>
						<FormattedMessage defaultMessage="An error ocurred" id="alert-error.an-error-occurred" />
					</AlertTitle>
					<AlertText>
						<FormattedMessage
							defaultMessage="Something went wrong at our end. Don't worry, we'll fix soon."
							id="forgot-password.error-text"
						/>
					</AlertText>
				</Alert>
			)}

			<SignUpForm form={form} onSubmit={handleSubmit} isInviteInitialLoading={inviteData.isInitialLoading} />

			<Loader isOpen={inviteData.isInitialLoading || form.formState.isSubmitting} />
		</>
	);
};
