import React, { useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import z from 'zod';

import { trpc } from '../../shared/trpc/client';
import { zodPhoneNumber } from '../../shared/zod-schemas';

import { Button, Center, Footer, FormInput, Text } from '../../base-ui/components';

import { useUserData } from '../contexts/hooks';
import { BackButton } from './back-button';

type Props = {
	onBack: () => void;
};

export const ChangePhoneNumber = ({ onBack }: Props) => {
	const {
		handlers: { forceRefetch },
	} = useUserData();

	const intl = useIntl();
	const schema = useMemo(() => z.object({ phone: zodPhoneNumber(intl) }), []);
	const form = useForm<Schema>({ resolver: zodResolver(schema) });
	type Schema = z.infer<typeof schema>;

	const { mutateAsync: handleChangePhoneNumber, isLoading } = trpc.user.update.useMutation();

	const handleSubmit = async ({ phone }: Schema) => {
		await handleChangePhoneNumber({ phone });
		await forceRefetch();
		onBack();
	};

	return (
		<Center>
			<BackButton onClick={onBack} />

			<Text type="title" center>
				<FormattedMessage defaultMessage="Change phone number" id="registration.change-phone-number" />
			</Text>

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					<FormInput
						name="phone"
						placeholder={intl.formatMessage({
							defaultMessage: 'Phone number',
							id: 'change-phone-number.phone-number',
						})}
						type="tel"
					/>

					<Footer>
						<Button htmlType="submit" disabled={isLoading} testId="sign-up-button-continue">
							<FormattedMessage defaultMessage="Continue" id="common.continue" />
						</Button>
					</Footer>
				</form>
			</FormProvider>
		</Center>
	);
};
